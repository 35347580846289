import { Component, EventEmitter, OnInit, OnChanges, Output, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { DialogComponent } from '../../../ui/dialog/dialog';

import { UserProvider } from '../../../providers/user/user';
import { ApiProvider } from '../../../providers/api/api';
import { HelpersProvider } from '../../../providers/helpers/helpers';
import { AuthService } from '../../../providers/auth/auth.service';
import { CustomerProvider } from '../../../providers/customer/customer';
import { UserPurchaseService } from '../../../providers/user/purchase';

@Component({
  selector: 'component-user-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class UserFormComponent implements OnInit {
  protected headers: any;
  formSpinner: boolean = false;
  codes: any = [];

  customerLabel: string = 'usuário';

  @Input() loading: boolean = false;
  @Input() user: any = null;
  @Input() ismobile: any = null;
  @Input() istablet: any = null;
  @Input() accountType: string = 'teacher';

  @Output() setUserUpdated = new EventEmitter();

  userAvatar: string = '';
  userBirthdate: any = null;
  userPremiumExpire: any = null;
  userData: any = null;
  userId: number = 0;
  userForm = new FormGroup({
    id: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
    password: new FormControl('', [Validators.required]),
    password_confirmation: new FormControl('', [Validators.required]),
    gender: new FormControl(''),
    birthdate: new FormControl(''),
    cref: new FormControl(''),
    crefito: new FormControl(''),
    crn: new FormControl(''),
    cpf: new FormControl(''),
    cnpj: new FormControl(''),
    nutriname: new FormControl(''),
    nobr: new FormControl(''),
    uf: new FormControl(''),
    city: new FormControl(''),
    ddi: new FormControl(''),
    whatsapp: new FormControl(''),
    instagram: new FormControl(''),
    account_type: new FormControl(''),
    premium_id: new FormControl(''),
    premium_expires_date: new FormControl(''),
    observations: new FormControl(''),
  });
  selectedUser: any = {
    active_sub: {
      pagarme_subscriber_id: 0
    }
  };
  userSuperTreinosPlanId = '0';
  activity = { 
    "is_educf" : false,
    "is_fisio": false,
    "is_nutri": false,
    "is_stdnt": false
  };
  imgrnd: number;

  cropCheckInterval: any;

  ufs: any;

  @ViewChild(DropzoneComponent, {static: false}) dzAvatar?: DropzoneComponent;
  public avatar: DropzoneConfigInterface = {
    paramName: 'avatar',
    resizeMethod: 'crop',
    resizeHeight: 150,
    resizeWidth: 150,
    addRemoveLinks: true,
    dictRemoveFile: 'REMOVER IMAGEM',
    dictCancelUpload: 'CANCELAR ENVIO',
    dictCancelUploadConfirmation: 'CONFIRMA CANCELAR ENVIO?',
    dictInvalidFileType: 'ARQUIVO INVÁLIDO',
    maxFiles: 1,
    transformFile: (file, done) => {
      this.cropCheckInterval = setInterval(() => { if (this.helpers.getLocallyStored('cropOngoing') === false) this.resetDropzoneUploads() }, 250);
      this.helpers.cropImage(file, done, 'dz-avatar', 300, 300);
    }
  };

  @ViewChild(DropzoneComponent, {static: false}) dzLogo?: DropzoneComponent;
  public logo: DropzoneConfigInterface = {
    paramName: 'logo',
    resizeMethod: 'crop',
    resizeHeight: 55,
    resizeWidth: 160,
    addRemoveLinks: true,
    dictRemoveFile: 'REMOVER IMAGEM',
    dictCancelUpload: 'CANCELAR ENVIO',
    dictCancelUploadConfirmation: 'CONFIRMA CANCELAR ENVIO?',
    dictInvalidFileType: 'ARQUIVO INVÁLIDO',
    maxFiles: 1,
    transformFile: (file, done) => {
      this.cropCheckInterval = setInterval(() => { if (this.helpers.getLocallyStored('cropOngoing') === false) this.resetDropzoneUploads() }, 250);
      this.helpers.cropImage(file, done, 'dz-logo', 500, 185);
    }
  };

  @ViewChild(DropzoneComponent, {static: false}) dzLogoPdf?: DropzoneComponent;
  public logopdf: DropzoneConfigInterface = {
    paramName: 'logopdf',
    resizeMethod: 'crop',
    resizeHeight: 55,
    resizeWidth: 160,
    addRemoveLinks: true,
    dictRemoveFile: 'REMOVER IMAGEM',
    dictCancelUpload: 'CANCELAR ENVIO',
    dictCancelUploadConfirmation: 'CONFIRMA CANCELAR ENVIO?',
    dictInvalidFileType: 'ARQUIVO INVÁLIDO',
    maxFiles: 1,
    transformFile: (file, done) => {
      this.cropCheckInterval = setInterval(() => { if (this.helpers.getLocallyStored('cropOngoing') === false) this.resetDropzoneUploads() }, 250);
      this.helpers.cropImage(file, done, 'dz-logo-pdf', 500, 185);
    }
  };

  constructor(
    public toastr: ToastrService,
    public auth: AuthService,
    public api: ApiProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    public studentProvider: CustomerProvider,
    public userProvider: UserProvider,
    public router: Router,
    public UserPurchaseService: UserPurchaseService,
  ) {
    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
    this.imgrnd = this.helpers.random(1000001, 9999999);
  }

  ngOnInit() {
    //
  }
  
  ngOnChanges() {
    this.ufs = this.helpers.returnUFs();
    this.codes = this.helpers.sortByProperty(this.helpers.getLocallyStored('ddi_codes'), 'nome');
    if (this.user === null || this.user.id === 0) {
      this.new();
    } else {
      this.selectedUser = this.user;
      this.loadForm(this.user);
    }
  }


  loadForm(user: any) {
    if (!user) return null;
    this.userAvatar = (user.details !== null) ? user.avatar : null;
    this.userId = user.id;
    this.userForm.patchValue(user);
    this.userForm.value.goal_id = user.goal_id;
    this.userForm.value.ddi = user.ddi;
    
    let offsetMs: any;
    if (user.birthdate !== null) {
      this.userBirthdate = new Date(user.birthdate);
      offsetMs = this.userBirthdate.getTimezoneOffset() * 3 * 60 * 1000;
      this.userBirthdate = new Date(this.userBirthdate.getTime() + offsetMs);
    }

    if (user.premium_expires_date !== null) {
      this.userPremiumExpire = new Date(user.premium_expires_date);
      offsetMs = this.userPremiumExpire.getTimezoneOffset() * 3 * 60 * 1000;
      this.userPremiumExpire = new Date(this.userPremiumExpire.getTime() + offsetMs);  
    }

    this.updateDropzoneParams(this.avatar);
    this.avatar.dictDefaultMessage = ((this.ismobile || this.istablet)
        ? 'Clique aqui para enviar a foto'
        : 'Arraste a foto até aqui'
    );

    this.activity = { 
      "is_educf" : user.is_educf,
      "is_fisio": user.is_fisio,
      "is_nutri": user.is_nutri,
      "is_stdnt": user.is_stdnt
    };   
  }

  disableInputId(id: string = '') {
    document.getElementById(id).setAttribute('disabled', 'disabled');
  }

  new() {
    this.userData = { id: 0, details : { ddi: '0055' } };
    this.userId = 0;
    this.userForm.reset();
    this.userForm.patchValue({ status: 2, details : { ddi: '0055', gender: 'm'} });
  }

  removeAvatar() {
    // console.log('remover avatar');

    const data = {
      'id': this.user.id,
      'avatar': null,
    }

    this.loading = true;
    this.userProvider.updateUser(data).then(
      (_user: any) => {
        // console.log(user);
        this.user.avatar = _user.avatar;
        this.loading = false;
        this.toastr.success('Sua foto foi removida com sucesso!', '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        this.loading = false;
        this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
      }
    );
  }

  save() {
    this.validateAllFormFields(this.userForm);

    console.log(this.userForm);

    if (this.userForm.value.password !== this.userForm.value.password_confirmation) {
      this.toastr.error('As senhas não conferem!', '', this.helpers.toastrCfgs);
      return false;
    }

    if (this.userForm.value.whatsapp === null || this.userForm.value.whatsapp === '') {
      this.toastr.error('O telefone/whatsapp não pode estar em branco!', '', this.helpers.toastrCfgs);
      return false;
    }

    const dataString = JSON.stringify(this.userForm.value);
    const data = JSON.parse(dataString);
    if (this.accountType === 'nutri') {
      if (data.goal_id === null) {
        data.goal_id = 1;
      }
    }
    if (data.birthdate) {
      data.birthdate = data.birthdate.substring(-10, 10);
    }
    if (data.premium_expires_date) {
      data.premium_expires_date = data.premium_expires_date.substring(-10, 10);
    }
    if (data.password === '' || data.password === null) {
      delete data.password;
      delete data.password_confirmation;
    }

    data.user_id = this.selectedUser.id;
    data.account_type = this.accountType;

    data.is_educf = (this.activity.is_educf) ? 1 : 0;
    data.is_fisio = (this.activity.is_fisio) ? 1 : 0;
    data.is_nutri = (this.activity.is_nutri) ? 1 : 0;
    data.is_stdnt = (this.activity.is_stdnt) ? 1 : 0;

    this.loading = true;

    if (this.userId > 0) {
      this.userProvider.updateUser(data).then(
        (user: any) => {
          // console.log(user);
          this.loading = false;
          this.toastr.success('Os dados foram atualizados com sucesso!', '', this.helpers.toastrCfgs);
          this.setUserUpdated.emit(user);
          this.new();
        },
        (err: any) => {
          this.loading = false;
          this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
        }
      );  
    } else {
      this.userProvider.createUser(data).then(
        (user: any) => {
          // console.log(user);
          this.loading = false;
          this.toastr.success('Conta criada com sucesso!', '', this.helpers.toastrCfgs);
          this.setUserUpdated.emit(user);
          this.new();
        },
        (err: any) => {
          this.loading = false;
          this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
        }
      );        
    }

  }

  returnUser(user: any = null) {
    this.setUserUpdated.emit(user);
  }

  delete(user: any) {
    this.deleteUserConfirmation(user);
  }

  deleteUserConfirmation(user: any): void {
    // console.log(student);
    const msg = 'Deseja realmente apagar este '+this.customerLabel+'? Todo o conteúdo dele será apagado!';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: 'Apagar '+this.customerLabel+'', message: msg, confirmation: 'deletar'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'deletar') {
        this.loading = true;
        this.userForm.reset();
        this.userProvider.deleteUser(user).then(
          (_res: any) => {
            this.loading = false;
            this.setUserUpdated.emit(user);
            this.toastr.success('O '+this.customerLabel+' foi apagado com sucesso!', '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(err, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  getUserFormValidationMessage(el: any) {
    if (el.errors.required) {
      return 'Este campo é obrigatório';
    }

    if (el.errors.incorrect || el.errors.pattern) {
      return 'O valor deste campo é inválido';
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  addIdPagarme() {
    console.log('-----------------------');
    console.log(this.selectedUser.active_sub.pagarme_subscriber_id);
    console.log(this.userForm.value.premium_expires_date);
    console.log(this.userForm.value.premium_id);
    console.log('-----------------------');

    let data = {
      user_id: this.user.id,
      pagarme_subscriber_id: this.selectedUser.active_sub.pagarme_subscriber_id,
      premium_id: this.userForm.value.premium_id,
      premium_expires_date: this.userForm.value.premium_expires_date,
    }
    
    this.UserPurchaseService.admSetNewPurchase(data).then(
      (user: any) => {
        // console.log(user);
        this.loading = false;
        this.toastr.success('Os dados foram atualizados com sucesso!', '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        this.loading = false;
        this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
      }
    );      
  }


  // dropzone funcs
  onAddedFile(_args: any) {
    //
  }

  onSendingFile(_$event: any) {
    //
  }

  onRemovedFile(_$event: any) {
    //
  }

  onUploadError(_$event: any) {
    //
  }

  onAvatarUploadSuccess($event: any) {
    // console.log($event);
    this.loading = false;
    this.user.id = $event[1].user.id;
    this.user.avatar = $event[1].user.avatar;
    this.toastr.success('Seu avatar foi enviado com sucesso!', '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.avatar.url = this.api.API_URL + '/v2/user/update/';
    this.avatar.params = {
      '_method': 'post',
      'id': this.user.id,
      'avatar': this.user.avatar,
    };

    this.auth.storeLocallyUser($event[1].user);
    (document.getElementById('profile-avatar') as HTMLFormElement).src = this.user.avatar;
  }

  onLogoUploadSuccess($event: any) {
    // console.log($event);
    this.loading = false;
    this.user = this.auth.retrieveLocalUser();
    this.user.id = $event[1].user.id;
    this.user.logo = $event[1].user.logo;
    this.toastr.success('Sua logo foi enviada com sucesso!', '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.logo.url = this.api.API_URL + '/v2/user/update/';
    this.logo.params = {
      '_method': 'post',
      'id': this.user.id,
      'logo': this.user.logo,
    };

    this.auth.storeLocallyUser(this.user);
    if (this.user.account_type === 'teacher') (document.getElementById('nav-teacher-super-coach-logo') as HTMLFormElement).src = ($event[1].user.logo);
    if (this.user.account_type === 'nutri') (document.getElementById('nav-nutri-mydietpro-logo') as HTMLFormElement).src = ($event[1].user.logo);
  }

  onLogoPdfUploadSuccess($event: any) {
    // console.log($event);
    this.loading = false;
    this.user = this.auth.retrieveLocalUser();
    this.user.id = $event[1].user.id;
    this.user.logo_pdf = $event[1].user.logo_pdf;
    this.toastr.success('Sua logo foi enviada com sucesso!', '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.logopdf.url = this.api.API_URL + '/v2/user/update/';
    this.logopdf.params = {
      '_method': 'post',
      'id': this.user.id,
      'logopdf': this.user.logo_pdf,
    };

    this.auth.storeLocallyUser(this.user);
  }

  updateDropzoneParams(dz: any): void {
    dz.headers = this.headers;
    dz.params = {
      'id': this.user.id,
      'avatar': (this.user.avatar === '' ? 'fake_cover_path' : this.user.avatar),
      'logo': (this.user.logo === '' ? 'default' : this.user.logo),
      'logopdf': (this.user.logo_pdf === '' ? 'default' : this.user.logo_pdf),
    };
    dz.url = this.api.API_URL + '/v2/user/update/';
  }

  resetDropzoneUploads(): void {
    this.dzAvatar.directiveRef.reset(true);
    this.dzLogo.directiveRef.reset(true);
    this.dzLogoPdf.directiveRef.reset(true);
  }

}
