import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../providers/auth/auth.service';

import { HelpersProvider } from '../../providers/helpers/helpers';
import { I18nProvider } from '../../providers/i18n/i18n';

import { codes } from '../../providers/helpers/countriesJson_ptBR';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  // styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent implements OnInit {

  STRINGS: any;
  PAGE: any;

  user: any = {};
  userForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
    email_confirmation: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
    cpf: new FormControl('', [Validators.required]),
    cnpj: new FormControl('', [Validators.required]),
    cref: new FormControl('', [Validators.required]),
    crefito: new FormControl('', [Validators.required]),
    crn: new FormControl('', [Validators.required]),
    nutriname: new FormControl('', [Validators.required]),
    nobr: new FormControl(''),
    ddi: new FormControl(''),
    whatsapp: new FormControl(''),
    password: new FormControl('', [Validators.required]),
    password_confirmation: new FormControl('', [Validators.required]),
    account_type: new FormControl('', [Validators.required]),
  });
  codes = codes;
  activity = { 
    "is_educf" : false,
    "is_fisio": false,
    "is_nutri": false,
    "is_stdnt": false
  };

  formSpinner: boolean = false;
  btIsDisabled: boolean = false;
  loading: boolean = false;
  confirmation: boolean = false;
  conta: number = 2;
  domain: string = '';

  constructor(
    public auth: AuthService,
    public toastr: ToastrService,
    private router: Router,
    public helpers: HelpersProvider,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('RegistrationPage');
  }

  ngOnInit() {
    //
    this.codes = this.helpers.sortByProperty(codes, 'nome');
    this.userForm.patchValue({ ddi: '0055' });
    this.domain = this.helpers.checkDomain();
  }

  back() {
    this.router.navigate(['login'], { skipLocationChange: true });
  }

  save() {
    // console.log(this.userForm);

    let cpfValid = this.helpers.TestaCPF(this.userForm.value.cpf);
    if (!cpfValid && !this.userForm.value.nobr) {
      this.toastr.error(this.PAGE.cpf_not_valid, '', this.helpers.toastrCfgs);
      return false;
    }

    this.validateAllFormFields(this.userForm);

    // let account = (this.conta === 5) ? 'partner' : 'teacher';
    let account: string;
    if (this.domain === 'mydietpro') account = 'nutri';
    if (this.domain === 'supercoach') account = 'teacher';
    this.userForm.patchValue({ account_type: account });

    if (!this.userForm.value.nobr) {
      if (this.userForm.value.cref === '' && this.activity.is_educf) {
        this.toastr.error(this.PAGE.cref_not_empty, '', this.helpers.toastrCfgs);
        return false;
      }
  
      if (this.userForm.value.crefito === '' && this.activity.is_fisio) {
        this.toastr.error(this.PAGE.crefito_not_empty, '', this.helpers.toastrCfgs);
        return false;
      }
  
      if (this.userForm.value.crn === '' && this.activity.is_nutri) {
        this.toastr.error(this.PAGE.crn_not_empty, '', this.helpers.toastrCfgs);
        return false;
      }

      if (this.userForm.value.nutriname === '' && this.activity.is_nutri) {
        this.toastr.error(this.PAGE.nutriname_not_empty, '', this.helpers.toastrCfgs);
        return false;
      }
    }

    if (this.userForm.value.password !== this.userForm.value.password_confirmation) {
      this.toastr.error(this.PAGE.senha_diferente, '', this.helpers.toastrCfgs);
      return false;
    }

    if (this.userForm.value.email !== this.userForm.value.email_confirmation) {
      this.toastr.error(this.PAGE.email_diferente, '', this.helpers.toastrCfgs);
      return false;
    }

    // if (this.userForm.invalid) return false;

    let prof = Object.assign(this.userForm.value);
    prof.is_educf = (this.activity.is_educf) ? 1 : 0;
    prof.is_fisio = (this.activity.is_fisio) ? 1 : 0;
    prof.is_nutri = (this.activity.is_nutri) ? 1 : 0;
    prof.is_stdnt = (this.activity.is_stdnt) ? 1 : 0;

    if (account === 'nutri') {
      prof.is_nutri = 1;
    } else {
      if (this.activity.is_educf === false && this.activity.is_fisio === false && this.activity.is_nutri === false && this.activity.is_stdnt === false) {
        this.toastr.error(this.PAGE.escolha_prof, '', this.helpers.toastrCfgs);
        return false;
      }  
    }

    prof.nobr = (this.userForm.value.nobr) ? 1 : 0;

      // console.log(prof);
      // return false;

    this.spinner(true);
    this.auth.registerUser(prof).then(
      res => {
        if (res.token) {
          this.user = res.user;
          this.auth.storeToken(res.token);
          this.auth.storeLocallyUser(this.user);
          this.auth.storeAdminHash();
          this.router.navigate(['professor/dashboard'], { skipLocationChange: true });
        }
      },
      err => {
        const toastrCfgs: any = { timeOut: 2500, positionClass: 'toast-bottom-right', toastClass: 'ngx-toastr toast-register'};
        console.log(err);
        // this.toastr.error(this.PAGE.email_cadastrado, '', toastrCfgs);
        this.toastr.error(err.toString().replace('Error:', ''), '', this.helpers.toastrCfgs);
        this.spinner(false);
      }
    );
  }

  setConfirmation() {
    this.confirmation = !this.confirmation;
  }


  getUserFormValidationMessage(el: any) {
    if (el.errors.required) {
      return this.PAGE.campo_obrigatorio;
    }

    if (el.errors.incorrect || el.errors.pattern) {
      return this.PAGE.campo_invalido;
    }
  }

  spinner(on: boolean = false) {
    this.formSpinner = on;
    this.btIsDisabled = on;
    (on) ? this.userForm.disable() : this.userForm.enable();
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  changeActivity(value: any) {
    value = !value;
  }

}
