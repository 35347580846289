import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';

import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { DialogComponent } from '../../../ui/dialog/dialog';
import { DialogInputCustomComponent } from '../../../ui/dialog-input-custom/dialog-input-custom';
import { DialogLightComponent } from '../../../ui/dialog-light/dialog-light';
import { DialogSelectFoodComponent } from '../../../ui/dialog-select-food/dialog-select-food';

import { AuthService } from '../../../providers/auth/auth.service';
import { HelpersProvider } from '../../../providers/helpers/helpers';
import { CustomerProvider } from '../../../providers/customer/customer';
import { UserProvider } from '../../../providers/user/user';
import { MenuProvider } from '../../../providers/menu/menu';
import { ApiProvider } from '../../../providers/api/api';
import { FoodProvider } from '../../../providers/food/food';
import { MealProvider } from '../../../providers/meal/meal';
import { I18nProvider } from '../../../providers/i18n/i18n';

import { environment } from '../../../environments/environment';

import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';


@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss']
})
export class MenusComponent implements OnInit {
  PAGE: any;
  STRINGS: any;

  @ViewChild('pdfTable') pdfTable: ElementRef;
  userLogo: any = 'assets/imgs/mydietpro/logo-topo-mydietpro.png';
  userLogoPDF: any = 'assets/imgs/mydietpro/logo-topo-mydietpro-roxo.png';
  userLogoPDFB64: any = '';
  backgroundLogo: '#4E4393';

  protected headers: any;
  section: string = 'account'
  ismobile: boolean = false;
  environment: string = environment.dev;
  user: any;
  userSuperTreinosPlanId: any;
  purchase: any = {
    supertreinos_plan_id: 0,
  };

  selectedFile: any;
  totalMBs: number = 0;
  maxMBs: number = 0;

  selectedMeal: any = {
    id: 0,
    hour_of_day: '00:00',
  };
  nameMealEN: string;
  nameMealES: string;

  noScroll: boolean = false;
  formSpinner: boolean = false;
  menuType: number = -1;
  formShow: boolean = false;
  step1: boolean = false;
  step2: boolean = false;
  loading: boolean = false;
  loadingMeal: boolean = false;
  pdfLoader: boolean = false;

  students: any;

  dataSource: MatTableDataSource<any>;
  // displayedColumns = ['select', 'name_clear', 'type', 'kcal', 'proteins', 'carbs', 'fat_total', 'total_students', 'menu'];
  displayedColumns = ['select', 'name_clear', 'type', 'kcal', 'proteins', 'carbs', 'fat_total', 'menu'];
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  allComplete: boolean = false;
  checkAllIndeterminate: boolean = false;
  checkedMenus = [];
  selectedMenus = [];
  total_selected = 0;
  menusOriList: any = [];
  totalFiles: number = 0;

  foods: any = [];
  showedFoods: any = [];

  searchStudent: any = {
    name: <string> '',
  };
  maxItens: number = 5;
  totalItens = { count: 0 };

  menus_totals: any = { total: <number> 0, max: <number> 0 };
  menus: Array<any> = [];
  menu: any = { id: <number> 0, name: <string> null, user_id: <number> 1, type: <number> -1,
    extra_texts: {
      suplementos: '',
      manipulados: '',
      compras: '',
    }
  };
  extra_suplementos: string = 'Texto exemplo:\n\n- Whey protein isolado\n- Creatina monohidratada\n- Multivitaminico & Mineral\n- Ômega 3\n- UC II';
  extra_manipulados: string = 'Texto exemplo:\n\nFórmula 1:\n- Metilfolato (1mg)\n- Pridoxal 5 Fosfato (25mg)\n\nFórmula 2:- Curumina (300mg)\n- Piperina (3mg)';
  extra_compras: string = 'Texto exemplo:\n\n- Arroz integral\n- Batata doce\n- Mandioca\n- Filé de frango\n- Tilápia\n- Banana';
  menuName = new FormControl('', [Validators.required]);
  allMeals: any;
  totalMenu: any = { kcal: <number> 0, proteins: <number> 0, fat_total: <number> 0, carbs: <number> 0, }

  
  // options
  data: any[];
  label: string = '';
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = false;
  isDoughnut: boolean = true;
  view: any[] = [340, 200];
  legendTitle: string = 'Cálculo';
  legendPosition: string = 'right';
  colorScheme = {
    domain: ['#59bbc8', '#ddd365', '#9e6ec7']
  };

  disablePage: boolean = false;

  constructor(
    public auth: AuthService,
    public toastr: ToastrService,
    public student: CustomerProvider,
    public userProv: UserProvider,
    public menuProv: MenuProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    public api: ApiProvider,
    public route: Router,
    private device: DeviceDetectorService,
    public foodProv: FoodProvider,
    public mealProv: MealProvider,
    public domSanitizer: DomSanitizer,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('MenusComponent');

    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
    this.dataSource = new MatTableDataSource();

    this.extra_suplementos = this.PAGE.extra_suplementos;
    this.extra_manipulados = this.PAGE.extra_manipulados;
    this.extra_compras = this.PAGE.extra_compras;
    this.legendTitle = this.PAGE.legend_title;
  }

  async ngOnInit() {
    this.ismobile = this.device.isMobile();
    this.user = this.auth.retrieveLocalUser();

    if ((this.user.crn === '' || this.user.crn === null || this.user.nutriname === '' || this.user.nutriname === null) && (this.user.nobr === 0)) {
      this.checkUserNutri();

    } else {
      this.getLogo();
      this.getSubscriptions();
      await this.helpers.getBase64ImageFromUrl(this.userLogoPDF).then((result: any) => { this.userLogoPDFB64 = result; }).catch(err => console.error(err));
    }
  }


  checkUserNutri() {    
    this.disablePage = true;
    const msg = '<center>'+this.PAGE.atualizar_crn+'</center>';
    const title = this.PAGE.atualizar_crn_title;
    const bt_ok = this.PAGE.atualizar_crn_bt_ok;
    const dialogRef = this.dialog.open(DialogLightComponent, {
      width: '360px',
      data: {title: title, message: msg, bt_ok: bt_ok, confirmation: 'ok'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        // this.loading = true;
      }
      this.userProv.goToProfile(this.route);
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  handlePageEvent(evt: any = null) {
    this.helpers.storeLocally('lastPageSizeFiles', evt.pageSize);
  }

 
  backToList() {
    this.formShow = false;
    this.step1 = false;
    this.step2 = false;
    this.menuType = -1;
    this.selectedMeal = { id: 0, hour_of_day: '00:00'};
    this.nameMealEN = '';
    this.nameMealES = '';
    this.menu = { id: 0, name: null, user_id: this.user.id, type: <number> -1};
    this.getMenusList();
  }
  goToNewMenu() {
    if (this.menus_totals.total >= this.menus_totals.max) {
      this.callModalLimit();
      return false;
    }

    this.formShow = true;
  }
  goToSelectType() {
    this.step1 = true;
  }
  setMenuType(n: number = null) {
    this.menuType = n;
    this.step2 = true;
    // this.saveMenu();
  }
  saveNewMenu() {
    this.saveMenu();
  }

  getFoods() {
    this.loading = true;
    // busca os alimentos
    this.foodProv.index().then(
      (res: any) => {
        this.loading = false;
        this.foods = res.foods;
      },
      (err: any) => {
        this.toastr.error(err, '', this.helpers.toastrCfgs);
        this.loading = false;
      }
    );
  }

  getSubscriptions() {
    this.loading = true;
    this.userProv.subscriptions().then(
      (p: any) => {
        this.purchase = p.purchase;
        this.menus_totals = p.menus; /* uncomment for test */ // this.menus_totals.max = 1;
        this.loading = false;
        this.userSuperTreinosPlanId = this.purchase.supertreinos_plan_id;
        this.setUserPlanId(this.purchase);

        if (this.user.account_type === 'teacher') {
          if ((this.userSuperTreinosPlanId !== '3' && this.userSuperTreinosPlanId !== '4' && this.userSuperTreinosPlanId !== 3 && this.userSuperTreinosPlanId !== 4)) {
            this.menus_totals.max = 2;
          }
        }

        // placeholder to test subscriptions
        // if (this.user.account_type === 'nutri') this.userSuperTreinosPlanId = 3;
        // if (this.user.account_type === 'nutri') this.user.subs.supertreinos_plan_id = 3;

        this.getLogo();
        this.getMenusList();
      },
      (err: any) => {
        this.loading = false;
        this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
      }
    );
  }

  getLogo() {
    this.user = this.auth.retrieveLocalUser();
    // console.log('updated logos 1');

    if (this.user.account_type === 'teacher') {
      // this.userLogo = this.domSanitizer.bypassSecurityTrustUrl(window.location.host) + '/assets/imgs/logo-topo-stcoach.png';
      this.userLogo = 'assets/imgs/logo-topo-stcoach.png';
      this.userLogoPDF = 'assets/imgs/logo-stcoach-pdf.png';
    }

    if (this.user.account_type === 'nutri') {
      this.userLogo = 'assets/imgs/mydietpro/logo-topo-mydietpro.png';
      this.userLogoPDF = 'assets/imgs/mydietpro/logo-topo-mydietpro-roxo.png';
    }
    
    if (this.user.subs === undefined) return false;

    if (this.user.account_type === 'teacher') {
      if (this.user.subs.supertreinos_plan_id && (
        this.user.subs.supertreinos_plan_id === '3' ||
        this.user.subs.supertreinos_plan_id === '4' ||
        this.user.subs.supertreinos_plan_id === 3 ||
        this.user.subs.supertreinos_plan_id === 4 ))
      {
        if (this.user.logo !== null && this.user.logo !== '') this.userLogo = this.user.logo;
        if (this.user.logo_pdf !== null && this.user.logo_pdf !== '') this.userLogoPDF = this.user.logo_pdf;
        // console.log('updated logos t');
      }
    }

    if (this.user.account_type === 'nutri') {
      if (this.user.subs.supertreinos_plan_id && (
        this.user.subs.supertreinos_plan_id === '12' ||
        this.user.subs.supertreinos_plan_id === 12 ))
      {
        if (this.user.logo !== null && this.user.logo !== '') this.userLogo = this.user.logo;
        if (this.user.logo_pdf !== null && this.user.logo_pdf !== '') this.userLogoPDF = this.user.logo_pdf;
        // console.log('updated logos n');
      }      
    }

    return true;
  }


  getMenusList() {
    this.loading = true;
    this.menuProv.index().then(
      (res: any) => {
        this.loading = false;
        this.menus = res.menus;
        const lastPageSize = this.helpers.getLocallyStored('lastPageSizeFiles');
        this.paginator._changePageSize(lastPageSize || 25);
        this.dataSource = new MatTableDataSource();

        this.processMenuList(res);
        this.getFoods();
        this.getCustomers();
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  processMenuList(res: any = null) {
    this.menus = res.menus;
    this.menusOriList = res.menus;
    // this.helpers.storeLocally('files', res.files);
    this.totalFiles = 0;
    for (let i=0; i<this.menus.length;i++) {
      this.totalFiles++;
      this.menus[i].total_students = this.menus[i].customer_users_menus?.length;
      this.menus[i].name_clear = this.helpers.removeDiacritics(this.menus[i].name);
      // 'type', 'kcal', 'proteins', 'carbs', 'total_fat',
      // this.menus[i].type = ();
      this.menus[i].kcal = 0;
      this.menus[i].proteins = 0;
      this.menus[i].carbs = 0;
      this.menus[i].fat_total = 0;      

      let menu = this.menus[i];
      if (menu.type === 1) {
        if (menu.total_menu) {
          this.menus[i].kcal = JSON.parse(menu.total_menu).kcal;
          this.menus[i].proteins = JSON.parse(menu.total_menu).proteins;
          this.menus[i].carbs = JSON.parse(menu.total_menu).carbs;
          this.menus[i].fat_total = JSON.parse(menu.total_menu).fat_total;
        }

      } else {
        for (let n=0; n<this.menus[i].meals.length;n++) {
          if (this.menus[i].meals[n].foods_total) {
            this.menus[i].kcal += parseFloat(this.menus[i].meals[n].foods_total.kcal);
            this.menus[i].proteins += parseFloat(this.menus[i].meals[n].foods_total.proteins);
            this.menus[i].carbs += parseFloat(this.menus[i].meals[n].foods_total.carbs);
            this.menus[i].fat_total += parseFloat(this.menus[i].meals[n].foods_total.fat_total);
          }
        }
        this.menus[i].kcal = ((this.menus[i].kcal).toFixed(2)).replace('.00','');
        this.menus[i].proteins = ((this.menus[i].proteins).toFixed(2)).replace('.00','');
        this.menus[i].carbs = ((this.menus[i].carbs).toFixed(2)).replace('.00','');
        this.menus[i].fat_total = ((this.menus[i].fat_total).toFixed(2)).replace('.00','');
      }
    }
    // console.log(this.students);

    this.dataSource = new MatTableDataSource(this.menus);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.setAll(false);
    this.selectedMenus = [];
  }

  getCustomers() {
    this.loading = true;
    this.student.getCustomers().then(
      (res: any) => {
        this.loading = false;
        this.students = this.helpers.sortByProperty(res.customers, 'name');
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
      }
    );
  }


  saveMenu() {
    this.loading = true;
    let valid = true;
    let el: any;
    this.menu.user_id = this.user.id;

    if (this.menu.name === '') {
      this.menuName.markAsTouched();
      valid = false;
    }
    if (valid === false) {
      this.loading = false;
      return false;
    }

    if (this.menu.id > 0) {
      this.updateMenu();
    }
    if (this.menu.id === 0) {
      this.createMenu();
    }
  }

  updateMenu() {
    this.loading = true;
    // if (this.menu.type === 1) this.menu.total_menu = JSON.stringify(this.totalMenu);
    console.log(this.menu.total_menu);
    console.log(this.totalMenu);
    this.menu.total_menu = JSON.stringify(this.totalMenu);
    this.menuProv.patch(this.menu).then(
      (res: any) => {
        // this.menu = res.menu;
        this.toastr.success(this.PAGE.menu_atualizado_sucesso, '', this.helpers.toastrCfgs);
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
      });
  }

  createMenu() {
    this.totalMenu = { kcal: 0, proteins: 0, fat_total: 0, carbs: 0, }
    this.menu.type = this.menuType;
    this.menuProv.post(this.menu).then(
      (res: any) => {
        this.menus = res.menus;
        this.menu = res.menu;
        this.toastr.success(this.PAGE.menu_cadastrado_sucesso, '', this.helpers.toastrCfgs);
        // this.loading = false;
        this.dataSource = new MatTableDataSource(this.menus);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.getSubscriptions();
    
        // this.backToList();
        // this.selectProgram(this.program.id);
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
      });
  }

  cloneMenu(menu: any) {

    this.loading = true;
    this.menuProv.clone(menu).then(
      (res: any) => {
        this.menus = res.menus;
        this.menu = res.menu;
        this.toastr.success(this.PAGE.menu_clonado_sucesso, '', this.helpers.toastrCfgs);
        this.loading = false;
        const lastPageSize = this.helpers.getLocallyStored('lastPageSizeFiles');
        this.paginator._changePageSize(lastPageSize || 25);
        this.dataSource = new MatTableDataSource();

        this.processMenuList(res);
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
      });    
  }

  async selectMenu(menu: any, i: any) {
    if (i+1 > this.menus_totals.max) return false;

    this.pdfLoader = true;
    await this.helpers.getBase64ImageFromUrl(this.userLogoPDF)
    .then((result: any) => { 
      this.userLogoPDFB64 = result; 
      this.pdfLoader = false;
    })
    .catch(err => console.error(err));  

    this.formShow = true;
    this.step1 = true;
    this.step2 = true;
    this.selectedMeal = { id: 0, hour_of_day: '00:00'};
    this.nameMealEN = '';
    this.nameMealES = '';
    this.menu = menu;

    this.menu.total_kcal = 0;
    this.menu.total_prot = 0;
    this.menu.total_gord = 0;
    this.menu.total_carb = 0;

    this.totalMenu = { kcal: 0, proteins: 0, fat_total: 0, carbs: 0, }    

    this.processTotalMenu();
    if (this.menu.type === 1) {
      if (this.menu.total_menu !== null && this.menu.total_menu !== undefined) this.totalMenu = JSON.parse(this.menu.total_menu);
    }

    this.foodsPDF(menu);
  }


  foodsPDF(menu: any) {
    let allMeals: Array<any> = [];
    let foods_total = { proteins: 0, fat_total: 0, carbs: 0, kcal: 0 };
    const meals = menu.meals;

    meals.forEach((meal: any) => {
      let allFoods: Array<any> = [];
      
      const foodsMeal = JSON.parse(meal.foods);
      foodsMeal.forEach((m_food: any, i: number) => {
        if (m_food.id === -1) {
          let row_food = {
            id: m_food.id,
            text: m_food.text,
          }
          allFoods.push(row_food);
        } else {
          this.foods.forEach((i_food: any) => {
            if (i_food.id === m_food.id) {
              let row_food = {
                id: i_food.id,
                name: i_food.name,
                translations: i_food.translations,
                qtd: m_food.user_grams,
                type: i_food.type,
                proteins: (parseFloat((i_food.proteins).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
                fat_total: (parseFloat((i_food.fat_total).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
                carbs: (parseFloat((i_food.carbs).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
                kcal: (parseFloat((i_food.kcal.replace(',','.'))) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
    
                colesterol: (parseFloat((i_food.colesterol.replace(',','.'))) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
                fat_mono: (parseFloat((i_food.fat_mono.replace(',','.'))) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
                fat_poli: (parseFloat((i_food.fat_poli.replace(',','.'))) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
                fat_saturated: (parseFloat((i_food.fat_saturated.replace(',','.'))) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
                fibers: (parseFloat((i_food.fibers.replace(',','.'))) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
                potassium: (parseFloat((i_food.potassium.replace(',','.'))) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
                sodium: (parseFloat((i_food.sodium.replace(',','.'))) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
                sugars: (parseFloat((i_food.sugars.replace(',','.'))) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2)
              }
              allFoods.push(row_food);
              foods_total.proteins = foods_total.proteins + (parseFloat((i_food.proteins).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.'))));
              foods_total.fat_total = foods_total.fat_total + (parseFloat((i_food.fat_total).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.'))));
              foods_total.carbs = foods_total.carbs + (parseFloat((i_food.carbs).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.'))));
              foods_total.kcal = foods_total.kcal + (parseFloat((i_food.kcal.replace(',','.'))) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.'))));
            }
          });
        }
      });

      let row_meal = {
        id: meal.id,
        user_id: meal.user_id,
        name: meal.name,
        customer_id: meal.customer_id,
        description: meal.description,
        foods: null,
        foods_total: {
          proteins: foods_total.proteins.toFixed(2),
          fat_total: foods_total.fat_total.toFixed(2),
          carbs: foods_total.carbs.toFixed(2),
          kcal: foods_total.kcal.toFixed(2),
        },
        hour_of_day: meal.hour_of_day,
        kcal: meal.kcal,
        menu_id: meal.menu_id,
        plan: meal.plan,
        sort: meal.sort,
      }
      foods_total = { proteins: 0, fat_total: 0, carbs: 0, kcal: 0 };

      row_meal.foods = allFoods;
      allMeals.push(row_meal);
    });
    this.allMeals = allMeals;
    console.log(allMeals);
  }

  downloadAsPDF() {
    // console.log(this.allMeals);

    const doc = new jsPDF();
    const pdfTable = this.pdfTable.nativeElement;
    // var html = htmlToPdfmake(pdfTable.innerHTML);
    var html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize: true,
      defaultStyles:{
        th:{
          fillColor:'#EEE',
        },
        thTransparent: {
          fillColor:'#FFF',
        }
      }      
    });
    let textoMarcaDagua = '';
    if (this.user.account_type === 'nutri' && (this.userSuperTreinosPlanId !== '11' && this.userSuperTreinosPlanId !== '12' && this.userSuperTreinosPlanId !== 11 && this.userSuperTreinosPlanId !== 12)) textoMarcaDagua = this.PAGE.marca_dagua_mydietpro;
    if (this.user.account_type === 'teacher' && (this.userSuperTreinosPlanId !== '3' && this.userSuperTreinosPlanId !== '4' && this.userSuperTreinosPlanId !== 3 && this.userSuperTreinosPlanId !== 4)) textoMarcaDagua = this.PAGE.marca_dagua_funcaex;
    if (this.user.account_type === 'teacher' && (this.userSuperTreinosPlanId === '0' || this.userSuperTreinosPlanId === 0)) textoMarcaDagua = this.PAGE.marca_dagua_stcoach;
    const documentDefinition = { 
      watermark: { text: textoMarcaDagua, opacity: 0.25, bold: true },
      content: html,
    };
    pdfMake.createPdf(documentDefinition).open();         
  }

  parseFoods(foods: any = null) {
    if (foods === null) return null;
    return JSON.parse(foods);
  }

  processTotalMenu() {
    this.totalMenu.kcal = 0;
    this.totalMenu.proteins = 0;
    this.totalMenu.fat_total = 0;
    this.totalMenu.carbs = 0;

    this.menu.meals.forEach((meal: any) => {
      let temp_data = this.preProcessData(meal, false);

      this.totalMenu.kcal = this.totalMenu.kcal + temp_data.kcal;
      this.totalMenu.proteins = this.totalMenu.proteins + temp_data.proteins;
      this.totalMenu.fat_total = this.totalMenu.fat_total + temp_data.fat_total;
      this.totalMenu.carbs = this.totalMenu.carbs + temp_data.carbs;
    });
  }

  preProcessData(meal: any, selectedFood: boolean = true) {
    this.showedFoods = [];
    let foods_total = {
      proteins: 0,
      fat_total: 0,
      carbs: 0,
      kcal: 0,
      colesterol: 0,
      fat_mono: 0,
      fat_poli: 0,
      fat_saturated: 0,
      fibers: 0,
      potassium: 0,
      sodium: 0,
      sugars: 0
    }
    const foodsMeal = JSON.parse(meal.foods);
    foodsMeal.forEach((m_food: any, i: number) => {
      if (m_food.id === -1) {
        let row_food = {
          id: m_food.id,
          text: m_food.text,
        }
        if (selectedFood) this.showedFoods.push(row_food);
      } else {
        this.foods.forEach((i_food: any) => {
          if (i_food.id === m_food.id) {
            let row_food = {
              id: i_food.id,
              name: i_food.name,
              translations: i_food.translations,
              qtd: m_food.user_grams,
              type: i_food.type,
              proteins: (parseFloat((i_food.proteins).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
              fat_total: (parseFloat((i_food.fat_total).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
              carbs: (parseFloat((i_food.carbs).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
              kcal: (parseFloat((i_food.kcal.replace(',','.'))) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
  
              colesterol: (parseFloat((i_food.colesterol.replace(',','.'))) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
              fat_mono: (parseFloat((i_food.fat_mono.replace(',','.'))) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
              fat_poli: (parseFloat((i_food.fat_poli.replace(',','.'))) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
              fat_saturated: (parseFloat((i_food.fat_saturated.replace(',','.'))) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
              fibers: (parseFloat((i_food.fibers.replace(',','.'))) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
              potassium: (parseFloat((i_food.potassium.replace(',','.'))) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
              sodium: (parseFloat((i_food.sodium.replace(',','.'))) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2),
              sugars: (parseFloat((i_food.sugars.replace(',','.'))) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2)
            }
  
            foods_total.proteins += parseFloat((parseFloat((i_food.proteins).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2));
            foods_total.fat_total += parseFloat((parseFloat((i_food.fat_total).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2));
            foods_total.carbs += parseFloat((parseFloat((i_food.carbs).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2));
            foods_total.kcal += parseFloat((parseFloat((i_food.kcal).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2));
            
            foods_total.colesterol += parseFloat((parseFloat((i_food.colesterol).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2));
            foods_total.fat_mono += parseFloat((parseFloat((i_food.fat_mono).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2));
            foods_total.fat_poli += parseFloat((parseFloat((i_food.fat_poli).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2));
            foods_total.fat_saturated += parseFloat((parseFloat((i_food.fat_saturated).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2));
            foods_total.fibers += parseFloat((parseFloat((i_food.fibers).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2));
            foods_total.potassium += parseFloat((parseFloat((i_food.potassium).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2));
            foods_total.sodium += parseFloat((parseFloat((i_food.sodium).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2));
            foods_total.sugars += parseFloat((parseFloat((i_food.sugars).replace(',','.')) * (parseFloat(m_food.user_grams) / parseFloat((i_food.grams).replace(',','.')))).toFixed(2));
            
            if (selectedFood) this.showedFoods.push(row_food);
  
          }
        });  
      }
    });
    
    if (selectedFood) {
      this.selectedMeal = meal;
      let translations = meal.translations;
      if (translations !== null) {
        this.nameMealEN = JSON.parse(translations).strings_en.name;
        this.nameMealES = JSON.parse(translations).strings_es.name;
      }
    }
    const totalNumber = {
      proteins: foods_total.proteins,
      fat_total: foods_total.fat_total,
      carbs: foods_total.carbs,
      kcal: foods_total.kcal,
      colesterol: foods_total.colesterol,
      fat_mono: foods_total.fat_mono,
      fat_poli: foods_total.fat_poli,
      fat_saturated: foods_total.fat_saturated,
      fibers: foods_total.fibers,
      potassium: foods_total.potassium,
      sodium: foods_total.sodium,
      sugars: foods_total.sugars
    }
    const total = {
      proteins: foods_total.proteins.toFixed(2),
      fat_total: foods_total.fat_total.toFixed(2),
      carbs: foods_total.carbs.toFixed(2),
      kcal: foods_total.kcal.toFixed(2),
      colesterol: foods_total.colesterol.toFixed(2),
      fat_mono: foods_total.fat_mono.toFixed(2),
      fat_poli: foods_total.fat_poli.toFixed(2),
      fat_saturated: foods_total.fat_saturated.toFixed(2),
      fibers: foods_total.fibers.toFixed(2),
      potassium: foods_total.potassium.toFixed(2),
      sodium: foods_total.sodium.toFixed(2),
      sugars: foods_total.sugars.toFixed(2)
    }
    if (selectedFood) this.selectedMeal.foods_total = total;
    this.processData(meal);

    return totalNumber;
  }

  processData(meal: any) {
    const foods_total = (meal.foods_total !== undefined) ? meal.foods_total : {proteins: 0, carbs: 0, kcal: 0};
    this.data = [
      {
        "name": this.PAGE.proteinas,
        "value": foods_total.proteins,
      },
      {
        "name": this.PAGE.lipidios,
        "value": foods_total.fat_total,
      },
      {
        "name": this.PAGE.carbo,
        "value": foods_total.carbs,
      }
    ];
    // setTimeout(() => {
    //   this.loading = false;
    // }, 2000);
  }

  removeFood(meal: any, fd_i: number = null) {
    const foods = JSON.parse(meal.foods);
    const userFoods = [];
    foods.map((food: any, i: number ) => {
      if (i !== fd_i) {
        if (Number(food.user_grams) > 0) {
          userFoods.push({ id: food.id, user_grams: food.user_grams });
        } else {
          if (food.text) userFoods.push({ id: -1, text: food.text });
        }        
      }
    });
    meal.foods = JSON.stringify(userFoods);
    this.processTotalMenu();
    this.preProcessData(meal);
  }

  addFood(meal: any) {
    const dialogRef = this.dialog.open(DialogSelectFoodComponent, {
      data: { food_list: this.foods },
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result !== undefined && result !== null && result !== '') {
        const foods = JSON.parse(meal.foods);
        const userFoods = [];
        foods.map((food: any) => {
          if (Number(food.user_grams) > 0) {
            userFoods.push({ id: food.id, user_grams: food.user_grams });
          } else {
            if (food.text) userFoods.push({ id: -1, text: food.text });
          }
        });
        userFoods.push(result);
        meal.foods = JSON.stringify(userFoods);
        // console.log(meal);

        this.processTotalMenu();
        this.preProcessData(meal);

      } else {
        // console.log(`Dialog result (no food): ${result}`);
      }
    });
  }

  addText(meal: any) {
    const foods = JSON.parse(meal.foods);
    foods.push({ id: -1, text: this.PAGE.texto_livre });
    meal.foods = JSON.stringify(foods);
    this.preProcessData(meal);
  }

  changeInputQtd(selectedMeal: any, i: number, evt: any) {
    const foods = JSON.parse(selectedMeal.foods);
    foods.map((food: any, fd_i: number) => {
      if (fd_i === i && food.id !== -1) {
        const value = (evt.target as any).value;
        food.user_grams = value;
      }
    });

    selectedMeal.foods = JSON.stringify(foods);
    this.processTotalMenu();
    this.preProcessData(selectedMeal);
  }

  changeFreeText(selectedMeal: any, i: number, evt: any) {
    const foods = JSON.parse(selectedMeal.foods);
    foods.map((food: any, fd_i: number) => {
      if (fd_i === i && food.id === -1) {
        const value = (evt.target as any).value;
        food.text = value;
      }
    });

    selectedMeal.foods = JSON.stringify(foods);
  }

  changeExtraText(menu: any, extra_text: any, evt: any) {
    let text = (menu[extra_text] !== undefined) ? (menu[extra_text]) : '';
    const value = (evt.target as any).value;
    text = value;

    menu[extra_text] = value;
  }

 
  // meal functions
  newMeal(menu_id: number = 0, emptyMenu: boolean = false) {
    this.loading = true;
    let data = {
      customer_id: 0,
      description: null,
      foods: null,
      hour_of_day: "06:00:00",
      kcal: "0",
      menu_id: menu_id,
      name: this.PAGE.nova_refeicao,
      plan: "plan_a",
      user_id: this.user.id,
      sort: -1
    }

    if (emptyMenu) {
      this.mealProv.get(menu_id).then(
        (_res: any) => {
          // console.log(res);
          this.menu.meals = this.checkMeals(_res);
          this.loading = false;
          this.toastr.success(this.PAGE.refeicoes_geradas, '', this.helpers.toastrCfgs);
        },
        (err: any) => {
          console.log(err);
          this.loading = false;
          this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        });

    } else {
      this.mealProv.post(data).then(
        (_res: any) => {
          // console.log(res);
          this.menu.meals = this.checkMeals(_res);
          this.loading = false;
          this.toastr.success(this.PAGE.nova_refeicao_sucesso, '', this.helpers.toastrCfgs);
        },
        (err: any) => {
          console.log(err);
          this.loading = false;
          this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        });
    }

  } 

  saveMeal(meal: any, menu: any) {
    this.loadingMeal = true;
    let data = Object.assign({}, meal);
    data.foods = JSON.parse(meal.foods);
    // data.hour_of_day = data.hour_of_day + ':00';
    let translations = { strings_en: { name: this.nameMealEN }, strings_es: { name: this.nameMealES } };
    data.translations = JSON.stringify(translations);

    // console.log(this.nameMealEN);
    // console.log(this.nameMealES);
    // console.log(data);
    // return false;

    if (data.hour_of_day === '' || data.hour_of_day === null) {
      data.hour_of_day = '00:00';
      meal.hour_of_day = '00:00';
    }

    this.mealProv.patch(data).then(
      (_res: any) => {
        // console.log(res);
        this.toastr.success(this.PAGE.refeicao_atualizada_sucesso, '', this.helpers.toastrCfgs);
        document.getElementById('meal-name-id-' + meal.id).innerHTML = this.helpers.returnTranslation(data);
        this.foodsPDF(menu);
        this.loadingMeal = false;
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loadingMeal = false;
      }); 
  }

  cloneMeal(meal: any) {
    const msg = this.PAGE.clonar_refeicao;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.clonar_refeicao_title, message: msg, confirmation: 'Sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.loading = true;
        this.mealProv.clone(meal).then(
          (_res: any) => {
            this.menu.meals = this.checkMeals(_res);
            this.loading = false;
            this.toastr.success(this.PAGE.clonar_refeicao_sucesso, '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            this.loading = false;
            console.log(err);
            this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
          }
        );
      }
    });  
  }

  deleteMeal(meal: any) {
    const msg = this.PAGE.remover_refeicao;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.remover_refeicao_title, message: msg, confirmation: 'Sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.loading = true;
        this.mealProv.delete(meal.id).then(
          (_res: any) => {
            this.menu.meals = this.checkMeals(_res);
            this.loading = false;
            this.toastr.success(this.PAGE.remover_refeicao_sucesso, '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            this.loading = false;
            console.log(err);
            this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
          }
        );
      }
    });  
  }

  checkMeals(data: any) {
    if (data) { if (data.meals) { if (data.meals.plan_a) { if (data.meals.plan_a.details) { return data.meals.plan_a.details; } } } }
    return [];
  }

  selectMeal(meal: any) {
    const elements = document.querySelectorAll('.food-table');
    elements.forEach((element) => { element.classList.add('hide'); });    
    
    if (this.selectedMeal.id !== meal.id) {
      this.preProcessData(meal);

      const seleted = 'item-' + meal.id;
      document.getElementById(seleted).classList.remove('hide');    
    } else {
      this.selectedMeal = {id: 0, hour_of_day: '00:00'};
      this.nameMealEN = '';
      this.nameMealES = '';
    }
  }

  reorderMeal(meals: any) {
    let meals_list = new Array;
    Object.assign(meals_list, meals);
    this.mealProv.reorder(meals_list).then(
      (_res: any) => {
        this.toastr.success(this.PAGE.reordenar_refeicao, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      });
  }

  drop(event: CdkDragDrop<string[]>) {
    const idxAtual = ( event.currentIndex );
    const idxAnter = ( event.previousIndex );
   
    moveItemInArray(this.menu.meals, idxAnter, idxAtual);
    this.reorderMeal(this.menu.meals);
  }


  // customer list functions
  customerHasMenu(customer: any, pid: number) {
    let checked: boolean = false;
    // verifica se o program tem algum aluno selecionado para ele
    customer.menus.forEach((menu: any) => {
      if (menu.menu_id === pid) {
        checked = true;
      }
    });
    return checked;
  }

  menuHasAllStudents(menu: any) {
    // console.log(file?.customer_users_menus?.length);
    // console.log(this.students?.length);
    if (menu?.total_users === this.students?.length) {
      return true;
    }
    return false;
  }

  toggleAllStudent(menu: any, v: boolean = false) {
    setTimeout(() => {
      this.loading = true;
      // v, o valor do slider é invertido porque usamos o evento click nele pra executar a funcao,
      // logo pega o estado inicial dele, nao o final como acontece com o evento change
      this.menuProv.admCustomerToggleAllMenuStudents(menu.id, !v).then(
        (_res: any) => {
          this.loading = false;
          this.students = _res.customers;
          if (this.user.account_type === 'teacher') this.toastr.success(this.PAGE.lista_atualizada, '', this.helpers.toastrCfgs);
          if (this.user.account_type === 'nutri') this.toastr.success(this.PAGE.lista_atualizada_pacientes, '', this.helpers.toastrCfgs);
          this.getMenusList();
        },
        (err: any) => {
          console.log(err)
          this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
          this.loading = false;
        }
      );
    }, 500);
  }

  toggleActiveMenu(cid: number, mid: number) {
    const data = {
      cid: cid,
      mid: mid
    };
    this.student.admCustomerToggleMenu(data).then(
      (_res: any) => {
        // this.loading = false;
        // this.students = res.customers;
        this.toastr.success(this.PAGE.lista_arquivos_atualizada, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
      }
    );
  }

  checkSlideValue(slide: any) {
    return slide._checked;
  }

  callModalLimit() {
    let msg = '';
    let bt_ok = '';
    let bt_cancel_hide = false;
    if (this.purchase.supertreinos_plan_id === "4") {
      msg = this.PAGE.modal_limite;
      bt_ok = this.STRINGS.bt_ok;
      bt_cancel_hide = true;
    } else {
      msg = this.PAGE.modal_limite_menus;
      bt_ok = this.STRINGS.bt_upgrade;
      bt_cancel_hide = false;
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      panelClass: 'subscribe-modal',
      width: '360px',
      data: {title: this.PAGE.menus_title, message: msg, confirmation: 'Sim', bt_ok: bt_ok, bt_cancel_hide: bt_cancel_hide}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.userProv.goToSubsPage(this.route);
      }
    });
  }   
  

  setUserPlanId(purchase: any = null) {
    let user = this.auth.retrieveLocalUser();
    this.userSuperTreinosPlanId = this.purchase.supertreinos_plan_id;
    user.subs.purchase = purchase;
    this.auth.storeLocallyUser(user);
  }

  helpFile() {
    let msg = '';
    msg += '<img src="assets/imgs/ajuda-arquivos.png">';

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '70vw', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }


  mouseScroll(scroll: boolean = true) {
    this.noScroll = !scroll;
  }


  // datatable functions
  cloneSelected() {
    if (this.menus_totals.total >= this.menus_totals.max) {
      this.callModalLimit();
      return false;
    }    

    if (this.selectedMenus.length < 1) {
      this.toastr.error(this.PAGE.nenhuma_prescricao, '', this.helpers.toastrCfgs);
      return false;
    }
    if (this.selectedMenus.length > 1) {
      this.toastr.error(this.PAGE.um_clone_vez, '', this.helpers.toastrCfgs);
      return false;
    }

    // console.log(this.selectedMenus);
    this.cloneMenu(this.selectedMenus[0]);

  }
  pdfSelected() {
    if (this.selectedMenus.length < 1) {
      this.toastr.error(this.PAGE.nenhuma_prescricao, '', this.helpers.toastrCfgs);
      return false;
    }
    if (this.selectedMenus.length > 1) {
      this.toastr.error(this.PAGE.um_pdf_vez, '', this.helpers.toastrCfgs);
      return false;
    }

    // console.log(this.selectedMenus);
    this.toastr.info(this.PAGE.gerando_pdf, '', this.helpers.toastrCfgs);
    this.menu = this.selectedMenus[0];
    this.foodsPDF(this.menu);
    this.helpers.getBase64ImageFromUrl(this.userLogoPDF)
    .then((result: any) => { 
      this.userLogoPDFB64 = result; 
      setTimeout(() => {
        this.downloadAsPDF();
      }, 1250);
    })
    .catch(err => console.error(err));  

  }
  deleteSelected() {
    if (this.selectedMenus.length < 1) {
      this.toastr.error(this.PAGE.nenhuma_prescricao, '', this.helpers.toastrCfgs);
      return false;
    }

    const msg = this.PAGE.apagar_prescricoes;
    const dialogRef = this.dialog.open(DialogInputCustomComponent, {
      width: '360px',
      data: {title: this.PAGE.apagar_prescricoes_title, placeholder: this.PAGE.escreva_deletar, inner_msg: msg, confirmation: 'sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result === this.PAGE.escreva_deletar_input) {
        this.loading = true;
        let idx = 0;
        for (let i = 0; i < (this.selectedMenus.length); i++) {
          this.menuProv.delete(this.selectedMenus[i].id).then(
            (_res: any) => {
              idx++;
              if (idx === this.selectedMenus.length) {
                this.getMenusList();
                this.selectedMenus = [];
                this.checkedMenus = [];
                this.allComplete = false;
                this.checkAllIndeterminate = false;
                this.toastr.success(this.PAGE.apagar_prescricoes_sucesso, '', this.helpers.toastrCfgs);
                this.getSubscriptions();
              }
              // this.toastr.success('Arquivo removido com sucesso do sistema!');
            },
            (err: any) => {
              this.loading = false;
              console.log(err);
              this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            }
          );
        }


        // this.studentProvider.admCustomerMassDelete(this.selectedMenus).then(
        //   (_res: any) => {
        //     this.loading = false;
        //     this.getSubscriptions();
        //     if (this.ismobile) this.backToList();
        //   },
        //   (err: any) => {
        //     console.log(err);
        //     this.toastr.warning(err);
        //     this.loading = false;
        //     if (this.ismobile) this.backToList();
        //   });
      }
    });
  }

  setAll(checked: boolean) {
    if (this.checkedMenus == null) return;
    if (!checked) this.checkAllIndeterminate = false;
    let count: number = 0;
    for (let i = 0; i < this.menus.length; i++) {
      this.checkedMenus[i] = checked;
      if (checked) this.selectedMenus.push(this.menus[i]);
      count++;
    }
    this.total_selected = (checked) ? count : 0;
  }

  changeCheck(checked: boolean, menu: any = null) {
    this.total_selected += (checked) ? + 1 : - 1;
    if (checked) {
      this.selectedMenus.push(menu);
    } else {
      for (let i = 0; i < this.selectedMenus.length; i++) {
        if (this.selectedMenus[i].id === menu.id) {
          this.selectedMenus.splice(i, 1);
        }
      }
    }
  }

  updateAllComplete() {
    let leastOne: boolean = false
    for (let i = 0; i < this.menus.length; i++) {
      if (this.checkedMenus[i]) {
        this.allComplete = ((i + 1) === this.menus.length && !leastOne) ? false : true;
        leastOne = true;
      } else {
        this.allComplete = false;
        if (leastOne) break;
      }
    }
    if (!this.allComplete && leastOne) this.checkAllIndeterminate = true;
    if (this.allComplete) this.checkAllIndeterminate = false;
    if (!leastOne) this.checkAllIndeterminate = false;
  }

  // chart functions
  onSelect(_data: any): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(_data)));
  }

  onActivate(_data: any): void {
    // console.log('Activate', JSON.parse(JSON.stringify(_data)));
  }

  onDeactivate(_data: any): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
