import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { MatSidenav } from '@angular/material/sidenav';
import { Meta, Title } from '@angular/platform-browser';
import { TooltipComponent } from '@angular/material/tooltip';

import { AuthService } from '../providers/auth/auth.service';
import { HelpersProvider } from '../providers/helpers/helpers';
import { UserProvider } from '../providers/user/user';

import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';

import { codes } from '../providers/helpers/countriesJson_ptBR';
import packageJson from '../../package.json';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  @ViewChild('snav', {static: false}) sidenav: MatSidenav;

  title = 'Área Administrativa';
  domain: string = '';
  minimize = false;
  public user: any = {};
  public version: string = packageJson.version;
  // public version: string = environment.version;
  stickyMsg: string = null;

  clearInterval: boolean = false;
  intervalCheckTeacherPlan: any;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );


  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    public auth: AuthService,
    public helpers: HelpersProvider,
    private titleService: Title,
    private metaService: Meta,
    private device: DeviceDetectorService,
    private toastr: ToastrService,
    public userProv: UserProvider,
    // private router: ActivatedRoute,
  ) {
    this.domain = this.helpers.checkDomain();

    Object.defineProperty(TooltipComponent.prototype, 'message', {
       set(v: any) {
           const el = document.querySelectorAll('.mat-tooltip');

           if (el) {
               el[el.length - 1].innerHTML = v;
           }
       },
    });

    if (environment.dev === 'homolog' && this.device.browser !== 'Safari') {
      this.metaService.addTag({ 'http-equiv':'Content-Security-Policy', content: 'upgrade-insecure-requests' });
    } else {
      document.body.classList.add('is-safari');
    }
  
  }

  ngOnInit() {
    console.log('[INICIANDO APLICACAO...]');

    this.stickyMsg = null;
    this.auth.checkUserHash();
    this.helpers.storeLocally('ddi_codes', this.helpers.sortByProperty(codes, 'nome'), 0);
    this.user = this.auth.retrieveLocalUser();
    this.checkTitle();

    // filtro para esconder os menus
    const hideMenu = (this.helpers.getParamValueQueryString('hm')) ? this.helpers.getParamValueQueryString('hm') : null;
    if (hideMenu === 1 || hideMenu === '1') {
      document.getElementById('main-body').classList.add('hide-menu');
    }    
    
    // filtro de url para o precadastro ou para anamnese
    const teacherLink = (this.helpers.getParamValueQueryString('p')) ? this.helpers.getParamValueQueryString('p') : null;
    const studentLink = (this.helpers.getParamValueQueryString('s')) ? this.helpers.getParamValueQueryString('s') : null;
    if (teacherLink !== null && studentLink === null) {
      // console.log('precadastro');
      this.route.navigate(['precadastro'], { skipLocationChange: true, queryParams: { code: teacherLink } });
      // console.log('precadastro?');
    }
    if (teacherLink !== null && studentLink !== null) {
      // console.log('anamnese');
      this.route.navigate(['anamnese'], { skipLocationChange: true, queryParams: { professor: teacherLink, aluno: studentLink } });
    }

    // filtro de url para acesso aos clientes supertreinos ou alunos supercoach
    const clientType = (this.helpers.getParamValueQueryString('ct')) ? this.helpers.getParamValueQueryString('ct') : null;
    const clientId = (this.helpers.getParamValueQueryString('ci')) ? this.helpers.getParamValueQueryString('ci') : null;
    if (clientType === 'cst' && clientId !== null) {
      this.route.navigate(['clientes/' + clientId], { skipLocationChange: true });
    }
    if (clientType === 'std' && clientId !== null) {
      this.route.navigate(['professor/alunos/' + clientId], { skipLocationChange: true });
    }

    // filtro de url para acesso direto vindo do app
    const tkn = (this.helpers.getParamValueQueryString('tkn')) ? this.helpers.getParamValueQueryString('tkn') : null;
    const tab = (this.helpers.getParamValueQueryString('tab')) ? this.helpers.getParamValueQueryString('tab') : null;
    // console.log(this.user);
    if (tkn !== null) {
      if (this.user === null) {
        this.auth.storeToken(tkn);
        this.auth.getUserByToken().then(
          (_res: any) => {
            // console.log(res);
            if (tab !== null) this.route.navigate(['professor/perfil/'], { skipLocationChange: true, queryParams: { tab: tab } });
          },
          (err: any) => {
            console.log(err);
          }
        );
      } else {
        if (tab !== null) this.route.navigate(['professor/perfil/'], { skipLocationChange: true, queryParams: { tab: tab } });
      }
    }

    if (this.user !== null && this.user.account_type !== null && this.user.account_type === 'teacher') {
      // this.checkDomain();
      this.checkTeacherPlan();
      this.updateAvatar();
    }

    if (this.user !== null && this.user.account_type !== null && this.user.account_type === 'nutri') {
      // this.checkDomain();
      this.checkTeacherPlan();
      this.updateAvatar();
    }
  }

  updateAvatar() {
    this.userProv.subscriptions().then(
      (p: any) => {
        this.user = this.auth.retrieveLocalUser();
        this.user.logo = p.teacher.logo;
        this.user.avatar = p.teacher.avatar;
        this.user.validation = p.teacher.validation;
        this.auth.storeLocallyUser(this.user);
      },
      (err: any) => {
        this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
      }
    );
  }

  checkTeacherPlan() {
    const seconds: number = 1000;
    const minutes: number = 60 * seconds;
    const intervalo: number = (environment.dev === 'prod') ? (15 * minutes) : (15 * seconds);

    this.intervalCheckTeacherPlan = setInterval(() => {
      this.listPayments();
    }, intervalo)
  }

  listPayments() {
    // this.toastr.info('verificando status da conta...', '', this.helpers.toastrCfgs);
    this.user = this.auth.retrieveLocalUser();
    if (this.user !== null && this.user.account_type !== null && this.user.account_type === 'teacher') {
      //
    } else {
      // console.log('User not logged to check account!');
      clearInterval(this.intervalCheckTeacherPlan);
      return false;
    }

    // console.log('Checking account...');
    this.userProv.getPayments().then(
      (res: any) => {
        this.clearInterval = true;
        res.payment_links.map((item: any) => {
          // console.log(item);
          // console.log('...' + item.pagarme_payment_link_status);
          if (item.pagarme_payment_link_status === 'active'
              || item.pagarme_payment_link_status === 'created'
              || item.pagarme_payment_link_status === 'expired') {
            this.clearInterval = false;
          }
        });
        if (this.clearInterval) {
          clearInterval(this.intervalCheckTeacherPlan);
          this.userProv.subscriptions().then(
            (_p: any) => {
              if (_p.purchase.changed_plan) {
                // this.toastr.success('seu plano foi atualizado', '', this.helpers.toastrCfgs);
                // console.log('...updated');
                this.stickyMsg = "A verificação do pagamento foi concluída. Recarregue a página para atualizar os dados do seu plano.";
              }
            },
            (err: any) => {
              clearInterval(this.intervalCheckTeacherPlan);
              console.log(err);
              // this.toastr.error('Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
            }
          );
        };

        // this.payment_list = p.payment_links;
      },
      (err: any) => {
        clearInterval(this.intervalCheckTeacherPlan);
        console.log(err);
        // this.toastr.error('Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
}
    );
  }


  toogleMini() {
    // console.log('minimenu');
    this.minimize = !this.minimize;
  }

  closeSnav() {
    this.sidenav.close();
    this.minimize = false;
  }

  checkDomain() {
    if (this.helpers.checkDomain() === 'supercoach') {
      if (this.user.account_type !== 'teacher') this.logout();
    }

    if (this.helpers.checkDomain() === 'supertreinos') {
      if (this.user.account_type !== 'admin' || this.user.account_type !== 'master') this.logout();
    }

    if (this.helpers.checkDomain() === 'mydietpro') {
      if (this.user.account_type !== 'nutri') this.logout();
    }
  }

  checkTitle() {
    let modulo: string = '';
    if (this.helpers.checkDomain() === 'supercoach') {
      // console.log('Admin Super Coach');
      // this.titleService.setTitle('Admin ST Coach');
      modulo = ' - Módulo ST Coach';
      this.title = 'ST Coach - Área Administrativa';
      this.titleService.setTitle('Sistema Administrativo ST Coach - v' + this.version);
    }

    if (this.helpers.checkDomain() === 'supertreinos') {
      // console.log('Admin Super Treinos');
      // this.titleService.setTitle('Admin Super Treinos');
      modulo = ' - Módulo ST Master';
      this.title = 'Super Treinos - Área Administrativa';
      this.titleService.setTitle('Sistema Administrativo Super Treinos - v' + this.version);
    }

    if (this.helpers.checkDomain() === 'mydietpro') {
      modulo = ' - Módulo My Diet Pro';
      this.title = 'My Diet Pro - Área Administrativa';
      this.titleService.setTitle('Sistema Administrativo My Diet Pro - v' + this.version);
    }

    return modulo;
  }

  logout() {
    this.helpers.clearDB();
    localStorage.clear();
    this.user = null;
    this.route.navigate(['login'], { skipLocationChange: true });
  }

}
