import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DialogLogoProComponent } from '../../../../ui/dialog-logo-pro/dialog-logo-pro';
import { DialogLightComponent } from '../../../../ui/dialog-light/dialog-light';

import { AuthService } from '../../../../providers/auth/auth.service';
import { HelpersProvider } from '../../../../providers/helpers/helpers';
import { UserProvider } from '../../../../providers/user/user';
import { ApiProvider } from '../../../../providers/api/api';
import { I18nProvider } from '../../../../providers/i18n/i18n';

import { codes } from '../../../../providers/helpers/countriesJson_ptBR';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'professores-dados-bancarios',
  templateUrl: './dados-bancarios.component.html',
  // styleUrls: ['./dados-bancarios.component.scss']
})
export class ProfessoresDadosBancariosComponent implements OnInit {

  PAGE: any;
  STRINGS: any;

  protected headers: any;
  section: string = 'account'
  codes = codes;
  ismobile: boolean = false;
  environment: string = environment.dev;
  customerLegend: string = 'Cliente';

  teachers: any = [];
  totalTeachers: number = 0;
  selectedTeacher: number = 0;
  user: any = {};
  selectedUser: any = {};
  userBirthdate: any;
  userActive: boolean = false;
  userForm = new FormGroup({
    id: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
    gender: new FormControl(''),
    birthdate: new FormControl(''),
    type: new FormControl(''),
    document: new FormControl(''),
    site_url: new FormControl(''),
    mother_name: new FormControl(''),
    professional_occupation: new FormControl(''),
    phone_numbers: new FormGroup({
      ddd: new FormControl(''),
      number: new FormControl(''),
      type: new FormControl(''),
    }),
    address: new FormGroup({
      street: new FormControl(''),
      complementary: new FormControl(''),
      street_number: new FormControl(),
      neighborhood: new FormControl(''),
      city: new FormControl(),
      state: new FormControl(),
      zip_code: new FormControl(),
      reference_point: new FormControl(),
    }),    
  });

  companyForm = new FormGroup({
    company_name: new FormControl(''),
    trading_name: new FormControl(''),
    email: new FormControl(''),
    document: new FormControl(''),
    type: new FormControl(''),
    site_url: new FormControl(''),
    annual_revenue: new FormControl(''),
    corporation_type: new FormControl(''),
    founding_date: new FormControl(''),
    main_address: new FormGroup({
      street: new FormControl(''),
      complementary: new FormControl(''),
      street_number: new FormControl(''),
      neighborhood: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      zip_code: new FormControl(''),
      reference_point: new FormControl(''),
    }),
    phone_numbers: new FormGroup({
      ddd: new FormControl(''),
      number: new FormControl(''),
      type: new FormControl(''),
    }),
    managing_partners: new FormGroup({
      name: new FormControl(''),
      email: new FormControl(''),
      document: new FormControl(''),
      type: new FormControl(''),
      mother_name: new FormControl(''),
      birthdate: new FormControl(''),
      monthly_income: new FormControl(''),
      professional_occupation: new FormControl(''),
      self_declared_legal_representative: new FormControl(''),
      address: new FormGroup({
          street: new FormControl(''),
          complementary: new FormControl(''),
          street_number: new FormControl(''),
          neighborhood: new FormControl(''),
          city: new FormControl(''),
          state: new FormControl(''),
          zip_code: new FormControl(''),
          reference_point: new FormControl(''),
      }),
      phone_numbers: new FormGroup({
        ddd: new FormControl(''),
        number: new FormControl(''),
        type: new FormControl(''),
      }),
    }),
  });

  transfer_settings: any = {
    transfer_enabled: <string> "false",
    transfer_interval: <string> "Daily",
    transfer_day: <number> 0
  };

  automatic_anticipation_settings: any = {
    enabled: <boolean> true,
    type: <string> "full",
    volume_percentage: <string> "50",
    delay: <string> "null"
  };

  default_bank_account: any = {
    holder_name: "Tony Stark",
    holder_type: "individual",
    holder_document: "26224451990",
    bank: "341",
    branch_number: "1234",
    branch_check_digit: "6",
    account_number: "12345",
    account_check_digit: "6",
    type: "checking"
  };

  // data sent to pagarme
  splitData: any = {
    code: "",
    register_information: null,
    transfer_settings: null,
    default_bank_account: null,
    automatic_anticipation_settings: null,
  }  

  formSpinner: boolean = false;
  btIsDisabled: boolean = false;
  loading: boolean = false;
  showFormCC: boolean = false;

  ufs: any;

  tabUser: string = '0';

  userTypeId: number = 0;

  loading_link: boolean = false;
  link: string = '';
  product: any;

  renew: string = '0';

  intervalCheckTeacherPlan: any;
  clearInterval: boolean = false;

  constructor(
    public auth: AuthService,
    public toastr: ToastrService,
    public userProv: UserProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    public api: ApiProvider,
    public router: Router,
    public route: ActivatedRoute,
    private device: DeviceDetectorService,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('ProfessoresComponent');

    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};

  }

  ngOnInit() {
    this.ismobile = this.device.isMobile();
    this.codes = this.helpers.sortByProperty(codes, 'nome');
    this.ufs = this.helpers.returnUFs();
    this.user = this.auth.retrieveLocalUser();

    this.selectedUser = this.user;
    this.loadForm(this.selectedUser);

    this.route.queryParams.subscribe(
      (params: any) => {
        // Defaults to 0 if no query param provided.
        this.tabUser = params['tab'] || '0';
        this.renew = params['renew'] || '0';

      }
    );

    this.customerLegend = this.STRINGS.DashboardPage.clientes;
    if (this.user.account_type === 'teacher') {
      this.customerLegend = this.STRINGS.aluno;
    }
    if (this.user.account_type === 'nutri') {
      this.customerLegend = this.STRINGS.paciente;
    }

  }


  save() {
    document.getElementById('teacher-form-card').scrollTop = 0;

    // console.log(this.userForm.value);
    // this.loading = true;
    // return false;
    // verifique se a senha esta sendo enviada vazia
    // this.validateAllFormFields(this.userForm);

    if (!this.userForm.value.nobr) {
      if (this.userForm.value.cpf === '' || this.userForm.value.cpf === null) {
        this.toastr.error(this.PAGE.cpf_empty, '', this.helpers.toastrCfgs);
        return false;
      }

      let cpfValid = this.helpers.TestaCPF(this.userForm.value.cpf);
      if (!cpfValid) {
        this.toastr.error(this.PAGE.cpf_not_valid, '', this.helpers.toastrCfgs);
        return false;
      }
  
    }

    if (this.userForm.value.password !== this.userForm.value.password_confirmation) {
      this.toastr.error(this.STRINGS.RegistrationPage.senha_diferente, '', this.helpers.toastrCfgs);
      return false;
    }


    // formata a data de nascimento
    const dataString = JSON.stringify(this.userForm.value);
    const data = JSON.parse(dataString);
    if (data.birthdate) {
      data.birthdate = data.birthdate.substring(-10, 10);
    }
    if (data.password === '') {
      delete data.password;
      delete data.password_confirmation;
    }

    data.user_id = this.selectedUser.id;


    this.loading = true;
    this.userProv.updateUser(data).then(
      (_user: any) => {
        // console.log(user);
        this.loading = false;
        this.toastr.success(this.PAGE.dados_atualizados_sucesso, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        this.loading = false;
        console.log(err)
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );

  }


  loadForm(user: any) {
    // console.log(user);
    // console.log(this.userForm.value);
    this.userForm.patchValue(user);
    this.userForm.value.goal_id = user.goal_id;
    if (!user.nobr) {
      if (user.cpf === '' || user.cpf === null) this.userForm.controls.cpf.markAsTouched();
    }

    this.userBirthdate = new Date(user.birthdate);
    const offsetMs = this.userBirthdate.getTimezoneOffset() * 3 * 60 * 1000;
    this.userBirthdate = new Date(this.userBirthdate.getTime() + offsetMs);

    if (this.userForm.value.instagram === null || this.userForm.value.instagram === '') {
      this.userForm.patchValue({'instagram':'https://www.instagram.com/'});
    }
  }

  getUserFormValidationMessage(el: any) {
    if (el.errors.required) {
      return this.STRINGS.campo_obrigatorio;
    }

    if (el.errors.incorrect || el.errors.pattern) {
      return this.STRINGS.campo_invalido;
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }


  toggleActiveTeacher(idx: any) {
    let msg: string;
    // console.log(this.teachers[idx].status);

    if (this.teachers[idx].status === 0) {
      msg = this.PAGE.aluno_desativado;
    } else if (this.teachers[idx].status === 1){
      msg = this.PAGE.aluno_ativado;
    } else if (this.teachers[idx].status === 9){
      msg = this.PAGE.aluno_bloqueado;
    }

    this.userProv.updateUser(this.teachers[idx]).then(
      (_user: any) => {
        this.toastr.success(msg, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        this.loading = false;
        console.log(err); this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }

  select(teacher: any) {
    this.selectedTeacher = teacher.id;
    this.selectedUser = teacher;
    this.loadForm(this.selectedUser);
    // this.getSubscriptions();
  }


  callProModal() {
    if (this.user.account_type === 'nutri') return false;
    const dialogRef = this.dialog.open(DialogLogoProComponent, {
      data: { date_selected: null },
    });

    dialogRef.afterClosed().subscribe(result => { console.log(result); });
  }

  setUserPlanId(purchase: any = null) {
    let user = this.auth.retrieveLocalUser();
    user.subs = purchase;
    this.auth.storeLocallyUser(user);
  }

  logout() {
    this.helpers.clearDB();
    localStorage.clear();
    this.user = null;
    this.router.navigate(['login'], { skipLocationChange: true });
  }

  helpSubs() {
    let msg = this.PAGE.ajuda_assinatura

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '70vw', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }


  removeAvatar() {
    // console.log('remover avatar');

    const data = {
      'id': this.selectedUser.id,
      'avatar': null,
    }

    this.loading = true;
    this.userProv.updateUser(data).then(
      (_user: any) => {
        // console.log(user);
        this.selectedUser.avatar = _user.avatar;
        this.loading = false;
        this.toastr.success(this.PAGE.foto_removida_sucesso, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        this.loading = false;
        console.log(err); this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }

  manageSubs() {
    window.open(this.user.subs.pagarme.manage_url, '_blank');
  }

  goToPix() {
    window.open('https://www.stcoach.app/pix', '_blank');
  }

  setNutriName() {
    if (this.userForm.value.nutriname === '' || this.userForm.value.nutriname === null) {
      this.userForm.patchValue({nutriname: this.userForm.value.name});
    } else {
      this.userForm.patchValue({nutriname: ''});
    }
  }


  copyTransfer(url: string = '') {
    console.log(url);
    if (this.helpers.copyTextToClipboard(url)) {
      this.toastr.success(this.STRINGS.link_transferencia, '', this.helpers.toastrCfgs);
    } else {
      this.toastr.error(this.STRINGS.copia_negada, '', this.helpers.toastrCfgs);
    }
  }

}
