import { Component, OnChanges, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { ProgramProvider } from '../../../providers/program/program';
import { HelpersProvider } from '../../../providers/helpers/helpers';
import { I18nProvider } from '../../../providers/i18n/i18n';

@Component({
  selector: 'dashboard-programs-goals',
  templateUrl: './programs-goals.html',
})
export class DashboardProgramsGoalsComponent implements OnChanges, OnInit {

  STRINGS: any;
  PAGE: any;

    @Input() user: any;
    @Input() programs: any;
    @Input() showLegend: boolean = true;

    loading: boolean = true;

    pre_data: any = {};
    data: Array<any> = [];
    data2: Array<any> = [];
    label: string = '';

    // options
    view: any[] = [275, 180];
    gradient: boolean = false;
    // showLegend: boolean = true;
    legendTitle: string = '';
    showLabels: boolean = false;
    isDoughnut: boolean = false;
    legendPosition: string = 'right';

    colorScheme = {
      domain: [ '#C1262F', '#FABC15', '#FDF305',
                '#86ED1E', '#19DDD5', '#1992DD',
                '#2819DD', '#DD51FF', '#D04FB2']
    };

    dataDiff = {
      data: [
        { name: 'iniciante', value: 0 },
        { name: 'intermediario', value: 0 },
        { name: 'avancado', value: 0 },
        { name: 'expert', value: 0 },
      ],
      title: '',

      // options
      view: [275, 180],
      gradient: false,
      showLegend: true,
      legendTitle: '',
      showLabels: false,
      isDoughnut: false,
      legendPosition: 'right',

      colorScheme: {
        domain: ['#00DD00', '#FFDD00', '#FF4400', '#dd51ff']
      },
    }

    constructor(
      public toastr: ToastrService,
      public programProv: ProgramProvider,
      public router: Router,
      public helpers: HelpersProvider,
      public i18n: I18nProvider,
    ) {
      //
      // Object.assign(this, { data: this.data });
      this.STRINGS = this.i18n.translate();
      this.PAGE = this.i18n.translate('DashboardPage');
    }

    ngOnInit() {

      this.data = [];
      this.legendTitle = this.PAGE.pg_objetivos;

      this.dataDiff.legendTitle = this.PAGE.pg_dificuldade;
      this.dataDiff.title = (this.user.subs.programs !== undefined) ? this.PAGE.pg_programas_cadastrados + ' - ' + this.user.subs.programs.total + '/' + this.user.subs.programs.max : this.PAGE.pg_programas_cadastrados + ': ' + this.programs.length;
    }

    ngOnChanges() {
      // console.log('mudou');
      this.postProcessDataGoal();
      this.postProcessDataDiff();
    }


    onSelect(_data: any): void {
      // console.log('Item clicked', JSON.parse(JSON.stringify(_data)));
    }

    onActivate(_data: any): void {
      // console.log('Activate', JSON.parse(JSON.stringify(_data)));
    }

    onDeactivate(_data: any): void {
      // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
    }

    postProcessDataGoal() {
      let data: Array<any> = [];
      if (this.programs.length !== undefined) {
        this.programs.map((program: any) => {
          if (program.goals[0] !== undefined) {
            if (this.pre_data[program.goals[0].id] === undefined) {
              this.pre_data[program.goals[0].id] = {name: (this.helpers.returnTranslation(program.goals[0])), qtd: 1};
            } else {
              this.pre_data[program.goals[0].id].qtd++;
            }
          }
        });
        // console.log(this.pre_data);
        Object.entries(this.pre_data).map((goal: any) => {
          data.push({"name": goal[1].qtd + " - " + this.helpers.returnTranslation(goal[1]), "value": goal[1].qtd});
        });
        this.data = data;
        this.label = this.PAGE.total_de + ' ' + this.PAGE.pg_programas_cadastrados + ': ' + this.programs.length;
        this.loading = false;
      }
    }

    onSelectDiff(_data: any): void {
      // console.log('Item clicked', JSON.parse(JSON.stringify(_data)));
    }

    onActivateDiff(_data: any): void {
      // console.log('Activate', JSON.parse(JSON.stringify(_data)));
    }

    onDeactivateDiff(_data: any): void {
      // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
    }


    postProcessDataDiff() {
      let iniciante: number = 0, intermediario: number = 0, avancado: number = 0, expert: number = 0;
      if (this.programs.length !== undefined) {
        this.programs.map((program: any) => {
          if (program.difficulty_level === 'Iniciante') iniciante++;
          if (program.difficulty_level === 'Intermediário') intermediario++;
          if (program.difficulty_level === 'Avançado') avancado++;
          if (program.difficulty_level === 'Expert') expert++;
        });

        this.dataDiff.data = [
          {
            "name": iniciante + " - " + this.STRINGS.iniciante,
            "value": iniciante
          },
          {
            "name": intermediario + " - " + this.STRINGS.intermediario,
            "value": intermediario
          },
          {
            "name": avancado + " - " + this.STRINGS.avancado,
            "value": avancado
          },
          {
            "name": expert + " - " + this.STRINGS.expert,
            "value": expert
          }
        ];
        this.label = this.PAGE.pg_programas_cadastrados + ' - ' + this.programs.length;
        if (this.user.subs !== undefined) {
          this.label = this.PAGE.pg_programas_cadastrados + ' - ' + this.programs.length;
          if (this.user.subs.programs !== undefined) {
            this.label = this.PAGE.pg_programas_cadastrados + ' - ' + this.user.subs.programs.total + '/' + this.user.subs.programs.max;
          }
        }
        this.loading = false;
      }
    }

    goToPrograms(fastBuild: boolean = false) {
      if (this.user.account_type === 'teacher') {
        if (fastBuild) {
          this.router.navigate(['professor/programas'], { skipLocationChange: true, queryParams: { fastBuild: true }  });
        } else {
          this.router.navigate(['professor/programas'], { skipLocationChange: true });
        }
      } else {
        this.router.navigate(['programas'], { skipLocationChange: true });
      }
    }

    goToTutorial() {
      window.open('https://www.stcoach.app/tutoriais-programas', '_system', 'location=yes');
    }

}
