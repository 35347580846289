import { Injectable } from '@angular/core';
import { ApiProvider } from '../api/api';


@Injectable()

export class UserPurchaseService {

  user: any;

  constructor(
    public api: ApiProvider,
  ) {
    //
  }

  async getListPaymentLink() {
    let message: any;
    return this.api.get('v2/user/payments').then(
      (res: any) => {
        return res;
      }
    ).catch(
      (err: any) => {
        if (err.status === 422 || err.status === 404) {
          message = err.error.err;
        } else {
          message = 'Algo não funcionou bem. Tente novamente.';
        }
        throw new Error(message);
      }
    );
  }

  async getPaymentLink(plan_id: any) {
    let message: any;
    return this.api.post('v2/user/payments', { plan_id }).then(
      (res: any) => {
        return res;
      }
    ).catch(
      (err: any) => {
        if (err.status === 422 || err.status === 404) {
          message = err.error.err;
        } else {
          message = 'Algo não funcionou bem. Tente novamente.';
        }
        throw new Error(message);
      }
    );
  }

  async admSetNewPurchase(data: any) {
    let message: any;
    return this.api.post('v2/user/pagarmeid', data).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }



}
