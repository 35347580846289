import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { map } from 'lodash';

import { environment } from '../../environments/environment';
import { reduce } from 'rxjs/operators';


@Injectable()
export class ApiProvider {

  protected headers: any;

  // API_ENV_URLS = {
  //   prod: 'https://supertreinosapp.com/api',
  //   homolog: 'http://142.93.235.119/api',
  //   local: 'http://supertreinos.loc/api'
  // };
  // API_URL = this.API_ENV_URLS.local;
  // login: piffardini@hotmail.com
  // pwd: $2y$10$3RaL/9J5kCRM9.0VBu/4Gu9N.dPhVX7.FiOGddVdAtWUq2sqzQDVq
  // pwdnew: $2y$10$/GXgl3IFi/GWBqCWVCw/OOYwQjN8/fotJBAwOv77vMdG6EQCpUr.K

  API_URL = environment.API_ENV_URLS;

  constructor(
    public http: HttpClient
  ) {
    this.headers = new HttpHeaders();
  }


  setToken(token: any) {
    if (!token) { return; }
    this.headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
  }

  getOptions(options: any) {
    this.setToken(localStorage.getItem('LoggedInUser'));

    let params = new HttpParams();
    map(options.params, (value: any, key: any) => params = params.append(key, value));
    return {
      reportProgress: true,
      headers: this.headers,
      params
    };
  }

  // TODO finish progress bar for http requests
  getOb(path: any, options: Options = {}): Observable<JSO> {
    const initialState: any = { state: 'PENDING', progress: 0 }
    const calculateState = (download: JSO, event: HttpEvent<unknown>): JSO => {
      console.log((this.isHttpProgressEvent(event)));
      if (this.isHttpProgressEvent(event)) {
        return {
          progress: event.total
            ? Math.round((100 * event.loaded) / event.total)
            : download.progress,
          state: 'IN_PROGRESS',
        }
      }
      if (this.isHttpResponse(event)) {
        return {
          progress: 100,
          state: 'DONE',
        }
      }
      return download;
    }

    return this.http.get(`${this.API_URL}/${path}`, this.getOptions(options)).pipe(reduce(calculateState, initialState));
  }


  get(path: any, options: Options = {}) {
    return this.http.get(`${this.API_URL}/${path}`, this.getOptions(options)).toPromise();
  }

  post(path: any, data = {}, options: Options = { headers: null }) {
    return this.http.post(`${this.API_URL}/${path}`, data, this.getOptions(options)).toPromise();
  }

  put(path: any, data = {}, options: Options = { headers: null }) {
    return this.http.put(`${this.API_URL}/${path}`, data, this.getOptions(options)).toPromise();
  }

  delete(path: any, options: Options = { headers: null }) {
    return this.http.delete(`${this.API_URL}/${path}`, this.getOptions(options)).toPromise();
  }

  download(path: any, options: Options = {}) {
    this.headers.append('responseType', 'arraybuffer');
    return this.http.get(`${this.API_URL}/${path}`, this.getOptions(options)).toPromise();
  }
  

  isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response
  }
   
  isHttpProgressEvent( event: HttpEvent<unknown> ): event is HttpProgressEvent {
    return (
      event.type === HttpEventType.DownloadProgress ||
      event.type === HttpEventType.UploadProgress
    )
  }

}

export interface JSO {
  progress: number;
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
}

interface Options {
  headers?: HttpHeaders;
  params?: object;
}
