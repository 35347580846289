import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../ui/dialog/dialog';
import { DialogSelectDateComponent } from '../../ui/dialog-select-date/dialog-select-date';
import { DialogInputCustomComponent } from '../../ui/dialog-input-custom/dialog-input-custom';
import { DialogValuationComponent } from '../../ui/dialog-valuation/dialog-valuation';

import { CustomerProvider } from '../../providers/customer/customer';
import { ProgramProvider } from '../../providers/program/program';
import { MenuProvider } from '../../providers/menu/menu';
import { GoalProvider } from '../../providers/goal/goal';
import { AuthService } from '../../providers/auth/auth.service';
import { UserProvider } from '../../providers/user/user';
import { ApiProvider } from '../../providers/api/api';
import { I18nProvider } from '../../providers/i18n/i18n';

import { HelpersProvider } from '../../providers/helpers/helpers';
import { codes } from '../../providers/helpers/countriesJson_ptBR';

@Component({
  selector: 'app-alunos',
  templateUrl: './alunos.component.html',
  // styleUrls: ['./alunos.component.scss']
})
export class AlunosComponent implements OnInit {
  PAGE: any;
  STRINGS: any;

  protected headers: any;
  teacherCode: string = '';
  codes: any;
  ismobile: boolean = false;
  istablet: boolean = false;

  customerLegend: string = 'Cliente';

  dataSource: MatTableDataSource<any>;
  displayedColumns = [
    'select',
    'status',
    'name_clear',
    'premium_expires_date',
    'total_programs',
    'total_files',
    'total_menus',
    'total_feedbacks_count',
    'total_anamneses_count',
    'total_valuations_count',
    'menu',
    // 'details'
  ];
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource_w: MatTableDataSource<any>;
  displayedColumns_w = ['select', 'name', 'created_at'];
  @ViewChild('paginator_w') paginator_w: MatPaginator;
  @ViewChild(MatSort) sort_w: MatSort;

  dataSource_d: MatTableDataSource<any>;
  // displayedColumns_d = ['select', 'name', 'deleted_at', 'restore', 'destroy'];
  displayedColumns_d = ['select', 'name', 'deleted_at', 'time_left'];
  @ViewChild('paginator_d') paginator_d: MatPaginator;
  @ViewChild(MatSort) sort_d: MatSort;

  tabStudents: number = 0;

  loading: boolean = false;
  expired: boolean = false;
  userPremiumExpires: any;
  students: any = [];
  studentsOriList: any = [];
  totalStudents: number = 0;
  waitline: any = [];
  deleteds: any = [];
  userBirthdate: any;
  userGoalId: number;
  goals: any = [];
  programs: any = [];
  originalPrograms: any = [];
  totalPrograms: number = 0;
  menus: any = [];
  originalMenus: any = [];
  user: any = [];
  route_id: string = '0';

  // student data
  student: any = null;
  studentData: any = null;
  studentSelected: boolean = false;
  studentId: number = 0;
  studentAvatar: string = '';
  studentAnamnesis: string = '';
  //

  account: {
    purchase: {
      supertreinos_plan_id: string,
    },
    students: {
      total: number,
      max: number,
      inactives: number,
    },
    programs: {
      total: number,
      max: number,
    },
    teacher : {
      link: any,
    }
  }
  menuSelected: string = '';

  allComplete: boolean = false;
  checkAllIndeterminate: boolean = false;
  checkedStudents = [];
  selectedStudents = [];
  total_selected = 0;

  allCompleteWait: boolean = false;
  checkAllIndeterminateWait: boolean = false;
  checkedStudentsWait = [];
  selectedStudentsWait = [];
  total_selected_wait = 0;

  allCompleteDeleted: boolean = false;
  checkAllIndeterminateDeleted: boolean = false;
  checkedStudentsDeleted = [];
  selectedStudentsDeleted = [];
  total_selected_deleted = 0;

  imgrnd: number;

  constructor(
    public auth: AuthService,
    public studentProvider: CustomerProvider,
    public programProvider: ProgramProvider,
    public menuProv: MenuProvider,
    public toastr: ToastrService,
    public goalProvider: GoalProvider,
    public dialog: MatDialog,
    public userProv: UserProvider,
    private router: Router,
    private route: ActivatedRoute,
    public api: ApiProvider,
    public helpers: HelpersProvider,
    private device: DeviceDetectorService,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('AlunosPage');

    this.ismobile = this.device.isMobile();
    this.istablet = this.device.isTablet();
    if (this.ismobile || this.istablet) this.displayedColumns = [
      'select',
      'name_clear',
      'status',
      'premium_expires_date',
      'total_programs',
      'total_files',
      'total_menus',
      'total_feedbacks_count',
      'total_anamneses_count',
      'total_valuations_count',  
      'menu',
      // 'details'
    ];
    this.user = this.auth.retrieveLocalUser();
    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
    this.teacherCode = this.route.snapshot.params.code;
    this.dataSource = new MatTableDataSource();
    this.dataSource_w = new MatTableDataSource();
    this.imgrnd = this.helpers.random(1000001,9999999);
    
  }

  ngOnInit() {
    this.helpers.storeLocally('ddi_codes', this.helpers.sortByProperty(codes, 'nome'), 0);
    this.codes = this.helpers.sortByProperty(codes, 'nome');
    this.helpers.clearState();
    if (this.teacherCode) {
      this.studentSelected = false;
      this.studentId = 0;
    } else {
      this.user = this.auth.retrieveLocalUser();
      this.route_id = (this.route.snapshot.params.id) ? this.route.snapshot.params.id : '0';
      if (this.route_id !== '0') this.loading;
      this.getSubscriptions();
    }

    if (this.user.account_type === 'teacher') {
      this.customerLegend = this.STRINGS.aluno;
    }
    if (this.user.account_type === 'nutri') {
      this.customerLegend = this.STRINGS.paciente;
    }
  }


  applyFilter(filterValue: string, tabIndex: number = 0) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    if (tabIndex === 0) this.dataSource.filter = filterValue;
    if (tabIndex === 2) this.dataSource_d.filter = filterValue;
  }

  handlePageEvent(evt: any = null) {
    this.helpers.storeLocally('lastPageSize', evt.pageSize);
  }

  checkList(evt: any = null) {
    if (!this.ismobile && !this.istablet) {
      setTimeout(() => {
        if (evt.index === 1) {
          this.menuSelected = 'waitline';
        } else {
          this.menuSelected = 'cadastro';
        }
      },500);
    }
    this.tabStudents = evt.index;
  }

  getUserUpdated(_user: any = null) {
    this.menuSelected = '';
    this.studentId = 0;
    // this.getStudent();
    this.getSubscriptions();
  }

  getUserListUpdated(students: any = null) {
    this.processStudentList(students);
  }

  getStudent() {
    this.imgrnd = this.helpers.random(1000001,9999999);
    this.loading = true;

    // busca os objetivos dos alunos
    this.goalProvider.index().then(
      (res: any) => {
        this.goals = res.goals;

        const lastPageSize = this.helpers.getLocallyStored('lastPageSize');
        this.paginator._changePageSize(lastPageSize || 25);
        this.dataSource = new MatTableDataSource();

        // busca os alunos
        this.studentProvider.getCustomers().then(
          (res: any) => {
            this.loading = false;
            this.getPrograms();
            this.getMenus();
            this.processStudentList(res);
            this.getCustomersWaiting();
            this.getCustomersDeleted();
            this.clearForm();

            this.route_id = (this.route.snapshot.params.id) ? this.route.snapshot.params.id : '0';
          },
          (err: any) => {
            console.log(err);
            this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
          }
        );

      },
      (err: any) => {
        console.log(err);
        this.toastr.error(err, '', this.helpers.toastrCfgs);
        this.loading = false;
    });
  }

  processStudentList(res: any = null) {
    this.students = res.customers;
    this.studentsOriList = res.customers;

    // this.helpers.storeLocally('students', res.customers);
    this.helpers.addCacheDB('cachedStudents', res);

    this.totalStudents = 0;
    // this.students.map(() => { this.totalStudents++; });
    for (let i=0; i<this.students.length;i++) {
      this.totalStudents++;
      this.students[i].total_programs = this.students[i].programs.length;
      this.students[i].total_files = this.students[i].files.length;
      this.students[i].total_menus = this.students[i].menus.length;
      this.students[i].name_clear = this.helpers.removeDiacritics(this.students[i].name);
    }
    // console.log(this.students);

    this.dataSource = new MatTableDataSource(this.students);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  // get waitline customers
  getCustomersWaiting() {
    this.loading = true;
    this.studentProvider.getCustomersWaiting().then(
      (res: any) => {
        this.waitline = res.customers;
        if (res.customers.length === 0) {
          this.waitline = [];
        }
        if (this.route_id === '-1') this.tabStudents = 1;
        this.dataSource_w = new MatTableDataSource(this.waitline);
        this.dataSource_w.paginator = this.paginator_w;
        this.dataSource_w.sort = this.sort_w;
        this.loading = false;
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }

  // list soft deleted customers
  getCustomersDeleted() {
    this.loading = true;
    this.studentProvider.getCustomersDeleted().then(
      (res: any) => {
        this.deleteds = res.customers;
        if (res.customers.length === 0) {
          this.deleteds = [];
        }
        if (this.route_id === '-1') this.tabStudents = 1;
        this.dataSource_d = new MatTableDataSource(this.deleteds);
        this.dataSource_d.paginator = this.paginator_d;
        this.dataSource_d.sort = this.sort_d;
        this.loading = false;
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }

  backToList() {
    this.menuSelected = '';
  }

  back() {
    this.studentSelected = false;
    this.menuSelected = '';
    this.studentId = 0;
    this.route_id = '0';
  }

  editGreetings() {
    this.loading = true;
    this.clearForm();
    setTimeout(() => {
      this.menuSelected = 'waitline';
      this.loading = false;
    },350);
  }

  new() {
    if (this.account.students.total >= this.account.students.max) {
      this.goToSubscribe();
    } else {
      this.loading = true;
      this.clearForm();
      setTimeout(() => {
        this.menuSelected = 'cadastro';
        this.loading = false;
      },350);
    }
  }

  clearForm() {
    this.route_id = '0';
    this.studentSelected = false;
    this.student = { id: 0, details : { ddi: '0055' } };
    this.studentData = { id: 0, details : { ddi: '0055' } };
    this.studentId = 0;
    if (!this.ismobile && !this.istablet) this.menuSelected = 'cadastro';
  }

  select(student: any) {
    // console.log('aluno selecionado');
    // console.log(student);
    if (student === null) return false;
    this.student = student;
    this.back();
    this.studentSelected = true;
    this.studentId = student.id;
    this.route_id = this.studentId.toString();
    this.studentAvatar = (student.details !== null) ? student.details.avatar : null;
    this.studentAnamnesis = (student.details !== null) ? student.details.anamnesis : null;
    this.studentData = student;

    // verifica se o aluno tem algum programa selecionado para ele
    this.programs = this.originalPrograms;
    this.programs.forEach(function (_p: any, index: number, programs: any = this.programs, std: any = student) {
      programs[index].student = 0;
      std.programs.some((program: any) => {
        return programs[index].student = (program.program_id === _p.id) ? 1 : 0;
      });
    });

    this.menus = this.originalMenus;
    this.menus.forEach(function (_p: any, index: number, menus: any = this.menus, std: any = student) {
      menus[index].student = 0;
      std.menus.some((menu: any) => {
        return menus[index].student = (menu.menu_id === _p.id) ? 1 : 0;
      });
    });

    // ordena os programas de acordo com a selecao do aluno
    this.sortStdProgram();

  }

  sortStdProgram() {
    // ordena os programas de acordo com a selecao do aluno
    const stdPrograms = [];
    const loosePrograms = [];
    this.programs.map((program: any) => {
      if (program.student === 1) {
        stdPrograms.push(program);
      } else {
        loosePrograms.push(program);
      }
    });

    this.programs = stdPrograms.concat(loosePrograms);
  }

  section(section: string = '') {
    this.menuSelected = section;
  }


  delete(student: any) {
    this.deleteStudentConfirmation(student);
  }

  deleteStudentConfirmation(student: any): void {
    // console.log(student);
    const msg = this.PAGE.apagar_aluno_a + student.name + this.PAGE.apagar_aluno_b;
    const dialogRef = this.dialog.open(DialogInputCustomComponent, {
      width: '360px',
      data: {title: this.PAGE.apagar_aluno_title, placeholder: this.PAGE.apagar_aluno_placeholder, inner_msg: msg, confirmation: 'sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === this.PAGE.apagar_aluno_confirmacao) {
        this.loading = true;
        this.studentProvider.admCustomerDelete(student).then(
          (_res: any) => {
            this.loading = false;
            this.toastr.success(this.PAGE.apagar_aluno_confirmacao_sucesso_a + student.name.split(' ')[0] + this.PAGE.apagar_aluno_confirmacao_sucesso_b, '', this.helpers.toastrCfgs);
            this.getSubscriptions();
            if (this.ismobile || this.istablet) this.backToList();
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(err, '', this.helpers.toastrCfgs);
            this.loading = false;
            if (this.ismobile || this.istablet) this.backToList();
          });
      }
    });
  }

  expire(student: any) {
    // console.log('seta expiracao do aluno');
    const dialogRef = this.dialog.open(DialogSelectDateComponent, {
      data: {
        title_innerhtml: this.PAGE.expiracao + this.customerLegend,
        label_date: this.PAGE.expiracao_lable,
        date_selected: student.premium_expires_date },
      panelClass: (this.user.account_type === 'nutri' ? 'np-dialog' : 'st-dialog'),
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('result: ' + result);
      if (result === undefined) return false;
      if (result !== '' || result !== null || result !== undefined) {
        let get = JSON.parse(JSON.stringify(result));
        if (get.date || get.clear) {
          if (get.date) student.premium_expires_date = get.date.substring(-10, 10);
          if (get.clear) student.premium_expires_date = null;

          const students: any = [];
          students.push(student);

          this.studentProvider.admCustomerUpdate(students).then(
            (_res: any) => {
              this.loading = false;
              this.toastr.success(this.PAGE.expiracao_msg_a + student.name.split(' ')[0] + this.PAGE.expiracao_msg_b, '', this.helpers.toastrCfgs);
              // this.getSubscriptions();
            },
            (err: any) => {
              this.loading = false;
              console.log(err);
              this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
              console.log(err);
            }
          );
        }
      }
    });
  }
  convertDate(user: any) {
    if (user.premium_expires_date) {
      this.userPremiumExpires = new Date(user.premium_expires_date);
      // const offsetMs = this.userPremiumExpires.getTimezoneOffset() * 3 * 60 * 1000;
      // this.userPremiumExpires = new Date(this.userPremiumExpires.getTime() - offsetMs);
      this.userPremiumExpires = new Date(this.userPremiumExpires.getTime());
    }
  }
  normalizeDate(date: any) {
    if (date === null) return date;
      return date.replace(/\s/g, "T");
  }

  getPrograms() {
    if (this.user) {
      this.programProvider.programs().then(
        (res: any) => {
          this.programs = res.programs;
          this.programs.forEach(function (_p: any, index: number, programs: any = this.programs) {
            programs[index].student = false;
          });
          this.totalPrograms = 0;
          this.originalPrograms = this.programs;
          this.programs.map((_prof: any) => {
            this.totalPrograms++;
          });

          if (this.route_id !== '0') {
            this.students.map((student: any) => {
              if (student.id === parseInt(this.route_id)) {
                this.select(student);
                this.section('cadastro');
                const studentCard = document.getElementById("std-" + this.route_id) as HTMLElement;
                if (studentCard !== null) setTimeout(() => { studentCard.scrollIntoView() }, 500);
              }
            });
          }
        },
        (err: any) => {
          console.log('Não foi possível retornar a lista de programas. Tente novamente.');
          console.log(err);
          // this.toastr.warning('Não foi possível retornar a lista de programas. Tente novamente.', '', this.helpers.toastrCfgs);
          this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        });
    }
  }

  getMenus() {
    this.menuProv.index().then(
      (res: any) => {
        this.menus = res.menus;
        this.originalMenus = this.menus;
        this.menus.forEach(function (_p: any, index: number, menus: any = this.menus) {
          menus[index].student = false;
        });

      },
      (err: any) => {
        console.log('Não foi possível retornar a lista de prescrições. Tente novamente.');
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }

  toggleActiveStudent(student: any) {
    if (this.user.account_type === 'teacher' && this.account.students.total > this.account.students.max) {
      this.goToSubscribe();      
    } else {

      let msg: string;
      // this.loading = true;
      if (student.status !== 9) {
        student.status = 9;
        msg = this.customerLegend + ' (' + student.name.split(' ')[0] + this.PAGE.cliente_ativo_bloqueado;
      } else {
        student.status = 2;
        msg = this.customerLegend + ' (' + student.name.split(' ')[0] + this.PAGE.cliente_ativo_desbloqueado;
      }

      const students: any = [];
      students.push(student);

      this.studentProvider.admCustomerUpdate(students).then(
        (_user: any) => {
          this.toastr.success(msg, '', this.helpers.toastrCfgs);
          // this.getSubscriptions();
        },
        (err: any) => {
          this.loading = false;
          console.log(err);
          this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        }
      );
    }
  }


  selectProgram(id: number) {
    console.log(id);
  }

  getStudentAge(birthdate: any) {
    if (birthdate === '' || birthdate === null) return 'N/D';
    birthdate = new Date(birthdate);
    const ageDifMs = Date.now() - birthdate.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  getUserFormValidationMessage(el: any) {
    if (el.errors.required) {
      return this.STRINGS.campo_obrigatorio;
    }

    if (el.errors.incorrect || el.errors.pattern) {
      return this.STRINGS.campo_invalido;
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  getSubscriptions() {
    this.loading = true;
    this.studentSelected = false;
    this.studentId = 0;

    // const account = this.helpers.getLocallyStored('teacherAccount');
    const account = null;

    if (account === null) {
      this.userProv.subscriptions().then(
        (p: any) => {
          this.account = p;
          this.account.teacher.link = window.location.href + '?p=' + this.account.teacher.link;
          this.loading = false;
          this.helpers.storeLocally('teacherAccount', p);
          this.getStudent();
        },
        (err: any) => {
          this.loading = false;
          console.log(err);
          this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        }
      );
    } else {
      this.account = account;
      this.loading = false;
      this.getStudent();
    }
  }

  checkExpire(student: any, mode: string = 'date') {
    if (!student.premium_expires_date) return null;
    const expires = new Date(student.premium_expires_date.replace(' ', 'T')).getTime();
    const now = Date.now();

    let distance: any = expires - now;

    if (distance < 0) {
      // return false;
      return true;
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    // const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    // const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    distance = '';

    if (mode === 'date') {
      if (days > 0 && days < 2) distance += days + ' dia'; // + minutes + "m " + seconds + "s ";
      if (days > 1) distance += days + ' dias';

      if (days > 0 && hours > 0) distance += ' e ';

      if (hours > 0 && hours < 2) distance += hours + ' h'; // hora
      if (hours > 1) distance += hours + ' h'; // horas
    }

    if (mode === '%') {
      distance = (((days * 24) + hours) / (30 * 24)) * 100;
      if (days > 30) {
        distance = 100;
      }
    }

    // return distance;
    return false;
  }


  toggleClass(item: any) {
    item.active = !item.active;
  }

  pageDeleteExpired() {
    const msg = this.PAGE.cliente_apagar;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.cliente_apagar_title, message: msg, confirmation: 'Sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.loading = true;
        this.studentProvider.admCustomerDeleteAllExpired().then(
          (res: any) => {
            this.loading = false;
            this.toastr.success(this.PAGE.cliente_apagar_sucesso, '', this.helpers.toastrCfgs);
            this.students = res.students;
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  pageUnlockValid() {
    const msg = this.PAGE.cliente_desbloquear;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.cliente_desbloquear_title, message: msg, confirmation: 'Sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.loading = true;
        this.studentProvider.admCustomerUnlockAllUnexpired().then(
          (_res: any) => {
            this.loading = false;
            this.toastr.success(this.PAGE.cliente_desbloquear_sucesso, '', this.helpers.toastrCfgs);
            this.getSubscriptions();
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  goToSubscribe() {
    let msg = '';
    let bt_ok = '';
    let bt_cancel_hide = false;
    if (this.account.purchase.supertreinos_plan_id === "4") {
      msg = this.PAGE.limite_maximo;
      bt_ok = this.STRINGS.bt_ok;
      bt_cancel_hide = true;
    } else {
      msg = this.PAGE.limite_clientes;
      bt_ok = this.STRINGS.bt_upgrade;
      bt_cancel_hide = false;
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      panelClass: 'subscribe-modal',
      width: '360px',
      data: {title: this.PAGE.cadastro_title, message: msg, confirmation: 'Sim', bt_ok: bt_ok, bt_cancel_hide: bt_cancel_hide}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.userProv.goToSubsPage(this.router);
      }
    });
  }      

  // students list functions
  activateSelected() {
    if (this.selectedStudents.length < 1) {
      this.toastr.error(this.PAGE.nenhuma_conta, '', this.helpers.toastrCfgs);
      return false;
    }
    //contador para verificar se o professor ultrapassou o limite de alunos ativos
    let counter = 0;
    this.loading = true;
    for (let i = 0; i < this.students.length; i++) {
      if (this.students[i].status === 2) counter++;
    }
    for (let i = 0; i < this.selectedStudents.length; i++) {
      this.selectedStudents[i].status = 2;
      counter++;
    }

    if (counter > this.account.students.max) {
      this.goToSubscribe();

      // this.toastr.error('Você ultrapassou o limite de alunos ativos. Inative alguns alunos ou faça um upgrade na sua conta.', '', this.helpers.toastrCfgs);
      // this.loading = false;
      // return false;
    } else {

      const msg = 'Os '+this.customerLegend+'s foram ativados com sucesso!';
      this.studentProvider.admCustomerUpdate(this.selectedStudents).then(
        (_user: any) => {
          this.loading = false;
          this.toastr.success(msg, '', this.helpers.toastrCfgs);
          this.setAll(false);
          this.allComplete = false;
          this.checkAllIndeterminate = false;
          this.getStudent();
        },
        (err: any) => {
          this.loading = false;
          this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
        }
      );
    }
  }
  blockSelected() {
    if (this.selectedStudents.length < 1) {
      this.toastr.error(this.PAGE.nenhuma_conta, '', this.helpers.toastrCfgs);
      return false;
    }
    this.loading = true;
    for (let i = 0; i < this.selectedStudents.length; i++) {
      this.selectedStudents[i].status = 9;
    }

    const msg = this.PAGE.alunos_bloqueados_sucesso;
    this.studentProvider.admCustomerUpdate(this.selectedStudents).then(
      (_user: any) => {
        this.loading = false;
        this.toastr.success(msg, '', this.helpers.toastrCfgs);
        this.setAll(false);
        this.allComplete = false;
        this.checkAllIndeterminate = false;
        this.getStudent();
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }
  deleteSelected() {
    if (this.selectedStudents.length < 1) {
      this.loading = false;
      this.toastr.error(this.PAGE.nenhuma_conta, '', this.helpers.toastrCfgs);
      return false;
    }

    // console.log(student);
    let msg = '';
    if (this.total_selected === this.students.length) {
      msg += this.PAGE.todas_as_contas;
    }
    if (this.total_selected > 5 && this.total_selected < this.students.length) {
      msg += this.PAGE.cinco_contas;
    }
    msg += this.PAGE.contas_apagar;
    const dialogRef = this.dialog.open(DialogInputCustomComponent, {
      width: '360px',
      data: {title: this.PAGE.contas_apagar_title, placeholder: this.PAGE.contas_apagar_placeholder, inner_msg: msg, confirmation: 'sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === this.PAGE.contas_apagar_confirmacao) {
        this.loading = true;
        this.studentProvider.admCustomerMassDelete(this.selectedStudents).then(
          (_res: any) => {
            this.loading = false;
            this.setAll(false);
            this.allComplete = false;
            this.checkAllIndeterminate = false;
            this.selectedStudents = [];
            this.checkedStudents = [];
            this.toastr.success(this.PAGE.contas_apagar_sucesso, '', this.helpers.toastrCfgs);
            this.getSubscriptions();
            if (this.ismobile || this.istablet) this.backToList();
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(err, '', this.helpers.toastrCfgs);
            this.loading = false;
            if (this.ismobile || this.istablet) this.backToList();
          });
      } else {
        let opts = this.helpers.toastrCfgs; opts.timeOut = 5000;
        this.toastr.info(this.PAGE.contas_apagar_falha, '', opts);
      }
    });
  }

  setAll(checked: boolean) {
    if (this.checkedStudents == null) return;
    if (!checked) this.checkAllIndeterminate = false;
    let count: number = 0;
    this.selectedStudents = [];
    for (let i = 0; i < this.students.length; i++) {
      this.checkedStudents[i] = checked;
      if (checked) this.selectedStudents.push(this.students[i]);
      count++;
    }
    this.total_selected = (checked) ? count : 0;
  }
  changeCheck(checked: boolean, student: any = null) {
    this.total_selected += (checked) ? + 1 : - 1;
    if (checked) {
      this.selectedStudents.push(student);
    } else {
      for (let i = 0; i < this.selectedStudents.length; i++) {
        if (this.selectedStudents[i].id === student.id) {
          this.selectedStudents.splice(i, 1);
        }
      }
    }
  }
  updateAllComplete() {
    let leastOne: boolean = false
    for (let i = 0; i < this.students.length; i++) {
      if (this.checkedStudents[i]) {
        this.allComplete = ((i + 1) === this.students.length && !leastOne) ? false : true;
        leastOne = true;
      } else {
        this.allComplete = false;
        if (leastOne) break;
      }
    }
    if (!this.allComplete && leastOne) this.checkAllIndeterminate = true;
    if (this.allComplete) this.checkAllIndeterminate = false;
    if (!leastOne) this.checkAllIndeterminate = false;
  }

  callValuationModal(customer: any) {
    const dialogRef = this.dialog.open(DialogValuationComponent, {
      panelClass: 'valuation-modal',
      width: '1200px',
      data: {customer: customer, title: this.PAGE.avalicao_title, message: 'teste', confirmation: 'Sim', bt_ok: this.STRINGS.bt_ok, bt_cancel_hide: 'cancelar'}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        console.log(customer);
      }
    });
  }


  // waitlist functions
  acceptSelected() {
    this.loading = true;

    if (this.selectedStudentsWait.length < 1) {
      this.toastr.error(this.PAGE.nenhuma_conta, '', this.helpers.toastrCfgs);
      return false;
    }

    for (let i = 0; i < this.selectedStudentsWait.length; i++) this.selectedStudentsWait[i].status = 9;
    const msg = this.PAGE.contas_ativadas_sucesso;

    this.studentProvider.admCustomerUpdate(this.selectedStudentsWait).then(
      (_user: any) => {
        this.loading = false;
        this.toastr.success(msg, '', this.helpers.toastrCfgs);
        this.setAllWait(false);
        this.allCompleteWait = false;
        this.checkAllIndeterminateWait = false;
        this.getStudent();
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }
  refuseSelected() {
    if (this.selectedStudentsWait.length < 1) {
      this.toastr.error(this.PAGE.precadastro_nao_selecionadas, '', this.helpers.toastrCfgs);
      return false;
    }

    // console.log(student);
    const msg = this.PAGE.precadastro_apagar;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.precadastro_apagar_title, message: msg, confirmation: 'deletar'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'deletar') {
        this.loading = true;
        this.studentProvider.admCustomerMassDelete(this.selectedStudentsWait).then(
          (_res: any) => {
            this.loading = false;
            this.setAllWait(false);
            this.allCompleteWait = false;
            this.checkAllIndeterminateWait = false;
            this.selectedStudentsWait = [];
            this.checkedStudentsWait = [];
            this.toastr.success(this.PAGE.precadastro_apagar_sucesso, '', this.helpers.toastrCfgs);
            this.getSubscriptions();
            if (this.ismobile || this.istablet) this.backToList();
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
            if (this.ismobile || this.istablet) this.backToList();
          });
      }
    });
  }

  setAllWait(checked: boolean) {
    if (this.checkedStudentsWait == null) return;
    if (!checked) this.checkAllIndeterminateWait = false;
    let count: number = 0;
    this.selectedStudentsWait = [];
    for (let i = 0; i < this.waitline.length; i++) {
      this.checkedStudentsWait[i] = checked;
      if (checked) this.selectedStudentsWait.push(this.waitline[i]);
      count++;
    }
    this.total_selected_wait = (checked) ? count : 0;
  }
  changeCheckWait(checked: boolean, student: any = null) {
    this.total_selected_wait += (checked) ? + 1 : - 1;
    if (checked) {
      this.selectedStudentsWait.push(student);
    } else {
      for (let i = 0; i < this.selectedStudentsWait.length; i++) {
        if (this.selectedStudentsWait[i].id === student.id) {
          this.selectedStudentsWait.splice(i, 1);
        }
      }
    }
  }
  updateAllCompleteWait() {
    let leastOne: boolean = false
    for (let i = 0; i < this.waitline.length; i++) {
      if (this.checkedStudentsWait[i]) {
        this.allCompleteWait = ((i + 1) === this.waitline.length && !leastOne) ? false : true;
        leastOne = true;
      } else {
        this.allCompleteWait = false;
        if (leastOne) break;
      }
    }
    if (!this.allCompleteWait && leastOne) this.checkAllIndeterminateWait = true;
    if (this.allCompleteWait) this.checkAllIndeterminateWait = false;
    if (!leastOne) this.checkAllIndeterminateWait = false;
  }

  accept(student: any) {
    const msg = this.PAGE.precadastro_aceite+this.customerLegend+'s?';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.precadastro_aceite_title+this.customerLegend+'', message: msg, confirmation: 'Sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.loading = true;
        student.status = 9;
        this.studentProvider.admCustomerUpdate(student).then(
          (_res: any) => {
            this.loading = false;
            this.toastr.success(this.PAGE.precadastro_aceite_sucesso, '', this.helpers.toastrCfgs);
            this.getStudent();
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(err, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  refuse(student: any) {
    const msg = this.PAGE.precadastro_remover;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.precadastro_remover_title+this.customerLegend+'', message: msg, confirmation: 'Sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.loading = true;
        this.studentProvider.admCustomerDelete(student).then(
          (_res: any) => {
            this.loading = false;
            this.toastr.success(this.PAGE.precadastro_remover_sucesso, '', this.helpers.toastrCfgs);
            this.getStudent();
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  // restore functions
  restoreSelected(student: any = [{}]) {
    const data = (JSON.stringify(student) === JSON.stringify([{}])) ? this.selectedStudentsDeleted : [student];

    if (this.account.purchase.supertreinos_plan_id !== '3' 
      && this.account.purchase.supertreinos_plan_id !== '4'
      && this.account.purchase.supertreinos_plan_id !== '11' 
      && this.account.purchase.supertreinos_plan_id !== '12'
    ){
      let msg = this.PAGE.conta_recuperacao_stcoach;
      if (this.user.account_type === 'nutri') msg = this.PAGE.conta_recuperacao_mydietpro;

      const dialogRef = this.dialog.open(DialogComponent, {
        width: '360px',
        data: {title: this.PAGE.conta_recuperacao_title, inner_msg: msg, confirmation: 'Sim', bt_ok: this.STRINGS.bt_upgrade}
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'Sim') {
          this.userProv.goToSubsPage(this.route);
        }
      });

    } else {

      if ((data.length + this.account.students.total) > this.account.students.max) {
        let msg = '';
        msg += (data.length > 1) 
            ? this.PAGE.conta_recuperacao_excede
            : this.PAGE.conta_recuperacao_excede_2;
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '360px',
          data: {show_alert: true, title: this.PAGE.conta_recuperacao_title, inner_msg: msg, confirmation: 'Sim', bt_ok: this.STRINGS.bt_upgrade}
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result === 'Sim') {
            this.userProv.goToSubsPage(this.route);
          }
        });

      } else {

        let msg = this.PAGE.conta_recuperacao_single;
        if (data.length === 0) {
          this.toastr.warning(this.PAGE.conta_recuperacao_none, '', this.helpers.toastrCfgs);
          return false;
        } else if (data.length >= 2) {
          msg = this.PAGE.conta_recuperacao_multi;
        }
    
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '360px',
          data: {title: this.PAGE.conta_recuperacao_title_2, message: msg, confirmation: 'Sim'}
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result === 'Sim') {
            this.loading = true;
            this.studentProvider.admCustomerMassRestore(data).then(
              (_res: any) => {
                this.loading = false;
                this.toastr.success(this.PAGE.conta_recuperacao_sucesso, '', this.helpers.toastrCfgs);
                this.setAllDeleted(false);
                this.allCompleteDeleted = false;
                this.checkAllIndeterminateDeleted = false;
                this.getSubscriptions();
              },
              (err: any) => {
                console.log(err);
                this.toastr.warning(err, '', this.helpers.toastrCfgs);
                this.loading = false;
              });
          }
        });
      }
    } 
  }

  destroySelected(student: any = [{}]) {
    const data = (JSON.stringify(student) === JSON.stringify([{}])) ? this.selectedStudentsDeleted : [student];
    let msg = this.PAGE.conta_exclusao;
    if (data.length === 0) {
      this.toastr.warning(this.PAGE.conta_exclusao_nenhuma, '', this.helpers.toastrCfgs);
      return false;
    } else if (data.length >= 2) {
      msg = this.PAGE.conta_exclusao_confirmacao;
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.conta_exclusao_title, message: msg, confirmation: 'Sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.loading = true;
        this.studentProvider.admCustomerMassDestroy(data).then(
          (_res: any) => {
            this.loading = false;
            this.toastr.success(this.PAGE.conta_exclusao_sucesso, '', this.helpers.toastrCfgs);
            this.setAllDeleted(false);
            this.allCompleteDeleted = false;
            this.checkAllIndeterminateDeleted = false;
            this.getStudent();
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(err, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });   
  }

  setAllDeleted(checked: boolean) {
    if (this.checkedStudentsDeleted == null) return;
    if (!checked) this.checkAllIndeterminateDeleted = false;
    let count: number = 0;
    this.selectedStudentsDeleted = [];
    for (let i = 0; i < this.deleteds.length; i++) {
      this.checkedStudentsDeleted[i] = checked;
      if (checked) this.selectedStudentsDeleted.push(this.deleteds[i]);
      count++;
    }
    this.total_selected_deleted = (checked) ? count : 0;
  }
  changeCheckDeleted(checked: boolean, student: any = null) {
    this.total_selected_deleted += (checked) ? + 1 : - 1;
    if (checked) {
      this.selectedStudentsDeleted.push(student);
    } else {
      for (let i = 0; i < this.selectedStudentsDeleted.length; i++) {
        if (this.selectedStudentsDeleted[i].id === student.id) {
          this.selectedStudentsDeleted.splice(i, 1);
        }
      }
    }
  }
  updateAllCompleteDeleted() {
    let leastOne: boolean = false
    for (let i = 0; i < this.deleteds.length; i++) {
      if (this.checkedStudentsDeleted[i]) {
        this.allCompleteDeleted = ((i + 1) === this.deleteds.length && !leastOne) ? false : true;
        leastOne = true;
      } else {
        this.allCompleteDeleted = false;
        if (leastOne) break;
      }
    }
    if (!this.allCompleteDeleted && leastOne) this.checkAllIndeterminateDeleted = true;
    if (this.allCompleteDeleted) this.checkAllIndeterminateDeleted = false;
    if (!leastOne) this.checkAllIndeterminateDeleted = false;
  }  

  returnDaysLeft(deleted_at: any) {
    if (!deleted_at) return null;
    const deleted = new Date(deleted_at.replace(' ', 'T')).getTime();
    const now = Date.now();
    const distance: any = deleted - now;
    const days_left = Math.floor(distance / (1000 * 60 * 60 * 24)) + 7; // 21 is the base days to perma delete 

    return (days_left > 0 && days_left < 2) ? days_left + ' ' + this.STRINGS.dia : days_left + ' ' + this.STRINGS.dias;
  }
  

  goToTutorial() {
    window.open('https://www.stcoach.app/tutoriais-aluno', '_system', 'location=yes');
  }

}
