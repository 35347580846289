import { Injectable } from '@angular/core';
import { ApiProvider } from '../api/api';

@Injectable()
export class TrainingProvider {

  constructor(
    public api: ApiProvider
  ) {
    //
  }

  trainings(programId = '') {
    const url = 'v2/trainings';
    return this.api.get(url, { params: { pid: programId } });
  }

  trainingsFull(programId = '') {
    const url = 'v2/trainings/full-workout';
    return this.api.get(url, { params: { pid: programId } });
  }

  training(id: any) {
    return this.api.get(`v2/trainings/${id}`);
  }

  patchTraining(training: any) {
    training._method = 'PATCH';
    return this.api.post(`v2/trainings/${training.id}`, training);
  }

  patchMassTraining(trainings: any) {
    return this.api.post(`v2/trainings/mass/`, trainings);
  }

  postTraining(training: any) {
    return this.api.post(`v2/trainings/`, training);
  }

  deleteTraining(id: any) {
    return this.api.delete(`v2/trainings/${id}`);
  }

  deleteMassTraining(tids: any) {
    return this.api.delete(`v2/trainings/mass`, { params: tids });
  }

  cloneTraining(training: any) {
    return this.api.post(`v2/trainings/clone/`, training);
  }

  sendTraining(training: any) {
    return this.api.post(`v2/trainings/send/`, training);
  }

  showTraining(training: any) {
    return this.api.post(`v2/trainings/publish/`, training);
  }

  hideTraining(training: any) {
    return this.api.post(`v2/trainings/unpublish/`, training);
  }

  reorderTraining(trainings: any) {
    return this.api.post(`v2/trainings/reorder/`, trainings);
  }

  tags() {
    return this.api.get(`v2/trainings/label-tags/`);
  }

  addTags(tags: any) {
    return this.api.post(`v2/trainings/label-tags/`, tags);
  }

  addTagToTrainings(tags: any) {
    return this.api.post(`v2/trainings/tags/`, tags);
  }

}
