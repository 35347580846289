import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { MatPaginatorIntl } from '@angular/material/paginator';

import { ToastrService } from 'ngx-toastr';
import { ProgramProvider } from '../../../providers/program/program';
import { CustomerProvider } from '../../../providers/customer/customer';
import { HelpersProvider } from '../../../providers/helpers/helpers';
import { I18nProvider } from '../../../providers/i18n/i18n';

import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'dashboard-tempo-consultoria',
  templateUrl: './tempo-consultoria.html',
})
export class DashboardTempoConsultoriaComponent implements OnInit {

  STRINGS: any;
  PAGE: any;

    @Input() user: any;
    loading = false;
    currentItemsToShow = [];
    studentsPerpage: number = 7;

    data: any[];
    students: Array<any> = null;

    constructor(
      public toastr: ToastrService,
      public programProv: ProgramProvider,
      public student: CustomerProvider,
      public router: Router,
      public helpers: HelpersProvider,
      private paginator: MatPaginatorIntl,
      private device: DeviceDetectorService,
      public i18n: I18nProvider,
    ) {
      //
      // Object.assign(this, { data: this.data });
      this.STRINGS = this.i18n.translate();
      this.PAGE = this.i18n.translate('DashboardPage');
      this.paginator.itemsPerPageLabel = this.STRINGS.items_pagina;
      this.paginator.previousPageLabel = this.STRINGS.anterior;
      this.paginator.nextPageLabel = this.STRINGS.items_proximo;
      this.paginator.getRangeLabel = (page: number, pageSize: number, length: number) => {
        length = Math.max(length, 0);
        if (length == 0 || pageSize == 0) {
          return '0 ' + this.STRINGS.de + ' $length';
        }
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return (startIndex + 1) + ' - ' + endIndex + ' de ' + length;
      };
    }

    ngOnInit() {
      // console.log(' - DashboardTempoConsultoriaComponent OnInit');
      this.getStudent();
      this.data = [];

      this.studentsPerpage = (this.device.isMobile()) ? 3 : 7;
    }



    onSelect(_data: any): void {
      // console.log('Item clicked', JSON.parse(JSON.stringify(_data)));
    }

    onActivate(_data: any): void {
      // console.log('Activate', JSON.parse(JSON.stringify(_data)));
    }

    onDeactivate(_data: any): void {
      // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
    }

    templatePhoneEmail(student: any) {
      // return `<div class="color-red">Top Template<button style="background: white; color: black">Press me</button></div>`;
      if (student.details == null) {
          return '';
      }
      return  '<div class="inner-tooltip">' +
              '<div class="email">' + (student.email)+ '</div>' +
              '<div class="phone">' + (student.details.phone || this.PAGE.no_phone)+ '</div>' +
              '<div class="arrow-down"></div>' +
              '</div>';
    }

    onPageChange($event: any) {
      this.currentItemsToShow = this.students.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }

    goToStudents() {
      if (this.user.account_type === 'teacher') {
        this.router.navigate(['professor/alunos/0'], { skipLocationChange: true });
      }
      if (this.user.account_type === 'nutri') {
        this.router.navigate(['nutri/pacientes/0'], { skipLocationChange: true });
      }
      if (this.user.account_type === 'master' || this.user.account_type === 'admin') {
        this.router.navigate(['clientes/0'], { skipLocationChange: true });
      }
    }

    goToStudent(student: any, _blank: boolean = false) {
      // console.log(student.details.phone);
      let url = window.location.href;
      this.helpers.clearState();
      let newWindow = url + '?ct=cst&ci=' + student.email;
      let basicUrl = 'clientes/' + student.email;

      if (this.user.account_type === 'teacher') {
        basicUrl = 'professor/alunos/' + student.id;
        newWindow = url + '?hm=1&ct=std&ci=' + + student.id;
      }

      if (this.user.account_type === 'nutri') {
        basicUrl = 'nutri/pacientes/' + student.id;
        newWindow = url + '?hm=1&ct=std&ci=' + + student.id;
      }

      // this.router.navigate([], { skipLocationChange: true }).then(_result => {  window.open(basicUrl, '_blank'); });
      if (_blank) {
        this.router.navigate([], { skipLocationChange: true }).then(_result => {  
          window.open(newWindow, '_blank', 'popup=true,width=1282,height=760'); 
        });
        // this.router.navigate([newWindow], { skipLocationChange: false });
      } else {
        this.router.navigate([basicUrl], { skipLocationChange: true });
      }
    }

    goToWhats(student: any) {
      // console.log(student);
      if (student.details.phone === null) {
        this.toastr.error(this.PAGE.tel_nao_cadastrado, '', this.helpers.toastrCfgs)
      } else {
        // window.open('https://api.whatsapp.com/send?phone=+' + student.details.phone, '_system', 'location=yes');
        window.open('https://wa.me/' + student.details.ddi.replace(/^0+(?!\.|$)/, '') + student.details.phone, '_system', 'location=yes');
      }
    }

    goToTutorial() {
      window.open('https://www.stcoach.app/tutoriais-aluno', '_system', 'location=yes');
    }

    returnBarPct(premium_expires_date: any) {
      if (premium_expires_date) {
        const pct = this.helpers.checkPremiumExpire(premium_expires_date, '%');
        // console.log(pct);
        return pct;
      }
    }

    returnBarClass(premium_expires_date: any) {
      let expire_percent = this.returnBarPct(premium_expires_date);

      let expire_class = 'success';

      if (expire_percent < 99) {
        expire_class = 'start';
      }
      if (expire_percent < 65) {
        expire_class = 'alert';
      }
      if (expire_percent < 35) {
        expire_class = 'danger';
      }
      if (expire_percent === 'expirado') {
        expire_class = 'danger';
      }

      // console.log(expire_class);
      return expire_class;
    }

    getStudent(force: boolean = false) {
      this.loading = true;
      // busca os alunos
      this.students = this.helpers.getLocallyStored('students');


      this.helpers.getCacheDB('cachedStudents').then(
        (cache: any) => {
          // console.log(cache);
          if (cache === undefined || cache === null || force) {
  
            if ((this.user.account_type === 'master') || (this.user.account_type === 'admin')) {
              this.students = null;
              this.student.getCustomersPremium().then(
                (res: any) => {
                  this.students = res.customers;
                  // this.helpers.storeLocally('students', res.customers);
                  this.helpers.addCacheDB('cachedStudents', res.customers);
                  this.currentItemsToShow = this.students.slice(0, this.studentsPerpage);
                  this.loading = false;
                },
                (err: any) => {
                  this.toastr.error(err, '', this.helpers.toastrCfgs);
                  this.currentItemsToShow = [];
                  this.loading = false;
                }
              );
            } else {
              this.student.getCustomers().then(
                (res: any) => {
                  this.students = res.customers;
                  // this.helpers.storeLocally('students', res.customers);
                  this.helpers.addCacheDB('cachedStudents', res.customers);
                  this.currentItemsToShow = this.students.slice(0, this.studentsPerpage);
                  this.loading = false;
                },
                (err: any) => {
                  this.toastr.error(this.PAGE.clientes_nao_retornados, '', this.helpers.toastrCfgs);
                  // this.toastr.error(err, '', this.helpers.toastrCfgs);
                  this.currentItemsToShow = [];
                  this.loading = false;
                }
              );
            }
  
          } else {
            this.students = null;
            setTimeout(() => {
              const data = JSON.parse(cache.data);
              this.students = data;
              this.currentItemsToShow = this.students.slice(0, this.studentsPerpage);
              this.loading = false;
            }, 500);            
          }
        },
        (err: any) => {
          console.log(err);
        }
      );         

    }
}
