import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';

import { HelpersProvider } from '../../providers/helpers/helpers';
import { ValuationProvider } from '../../providers/valuation/valuation';
import { I18nProvider } from '../../providers/i18n/i18n';
import { ProgramProvider } from '../../providers/program/program';

import { environment } from '../../environments/environment';

@Component({
  selector: 'dialog-overall',
  templateUrl: 'dialog-overall.html',
})

export class DialogOverallComponent {
  constructor(
    public toastr: ToastrService,
    private router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogOverallComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = {},
    public helpers: HelpersProvider,
    public valuationProv: ValuationProvider,
    public i18n: I18nProvider,
    public programProvider: ProgramProvider,
    private device: DeviceDetectorService,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('DialogOverallComponent');
    this.ismobile = this.device.isMobile();
  }

  ismobile: boolean = false;
  PAGE: any;
  STRINGS: any;

  protected API = (environment.API_ENV_URLS).replace('/api', '/');

  loading: boolean = false;

  user: any = { gender: 'masc' };
  customer: any;
  program: any = {
    title: '',
    description: '',
    goals: [],
    difficulty_level: '',
    weeks: '',
    days_per_week: '',
    minutes_per_day: '',
    trainings: [],
  }
  selectedTraining: any = null;

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));

    this.loading = true;

    this.programProvider.overall(this.data.program.id).then(
      (res: any) => {
        this.loading = false;
        console.log(res);
        this.program = res.program;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      });

  }

  selectTraining(training: any = null) {
    // console.log(training);
    this.selectedTraining = training;
  }


}
