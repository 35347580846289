import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiProvider } from '../api/api';
import { HelpersProvider } from '../helpers/helpers';


@Injectable()

export class AuthService {

  user: any;

  constructor(
    private myRoute: Router,
    public api: ApiProvider,
    public helpers: HelpersProvider,
  ) {
    //
  }

  async getUserNameAndEmail(email: any) {
    let message: any;
    return this.api.post('v2/user/get', { email }).then(
      (res: any) => {
        return this.storeLocallyUser(res.user);
      },
      (err: any) => {
        console.log(err);
        if (err.status === 422 || err.status === 404) {
          message = err;
        } else {
          message = 'Algo não funcionou bem. Tente novamente.';
        }
        return message;
        // throw new Error(message);
      }
    );
  }


  async getLoginToken(email: any, password: any) {
    let message: any;
    return this.api.post('v2/user/login', { email, password }).then(
      (res: any) => {
        this.storeLocallyUser(res.user);
        return res;
      },
      (err: any) => {
        if (err.status === 422 || err.status === 404 || err.status === 401 || err.status === 403) {
          message = err;
        } else {
          message = 'Algo não funcionou bem. Tente novamente.';
        }
        return message;
        // throw new Error(message);
      }
    );
  }  

  async recoverPassword(email: any, teacher: boolean = false) {
    let message: any;
    let url: any = (teacher) ? 'v2/user/recover' : 'mobile/recover/customer';
    return this.api.post(url, { 'email' : email }).then(
      (res: any) => {
        return res;
      }
    ).catch(
      (err: any) => {
        console.log(err);
        message = 'Algo não funcionou bem. Tente novamente.';
        if (err.status === 422 || err.status === 404) {
          message = err.error.err;
        }
        if (err.status === 400) {
          message = err.error.message;
        }

        throw new Error(message);
      }
    );
  }

  async getUserByToken() {
    let message: any;
    return this.api.post('v2/user/token').then(
      (res: any) => {
        return this.storeLocallyUser(res.user);
      }
    ).catch(
      (err: any) => {
        console.log(err);
        if (err.status === 422 || err.status === 404) {
          message = err.error.err;
        } else {
          message = 'Algo não funcionou bem. Tente novamente.';
        }
        this.logout();
        throw new Error(message);
      }
    );
  }

  async registerUser(data: any) {
    return this.api.post('v2/user/create', data).then(
      (res: any) => {
        this.storeLocallyUser(res.user);
        return res;
      },
      (err: any) => {
        console.log(err);
        let message: any;
        if (err.status === 422 || err.status === 404) {
          message = err.error.err;
        } else {
          message = 'Algo não funcionou bem. Tente novamente.';
        }
        throw new Error(message);
      }
    );
  }

  async getCustomerLogin(email: any, password: any) {
    let message: any;
    return this.api.post('mobile/login', { email, password }).then(
      (res: any) => {
        res.user.fav_list = [];
        res.user.fav_trainings = [];
        // console.log('client login');
        // console.log(res);
        this.storeLocallyUser(res.user);
        return res;
      }
    ).catch(
      (err: any) => {
        console.log(err);
        if (err.status === 422 || err.status === 404) {
          message = err.error.message;
        } else {
          message = 'Algo não funcionou bem. Tente novamente.';
        }
        throw new Error(message);
      }
    );
  }

  async updateCustomer(data: any) {
    return this.api.put('v2/mobile/customer/update', data).then(
      (res: any) => {
        return this.storeLocallyUser(res.customer);
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }

  async admCustomerUpdate(data: any) {
    return this.api.put('v2/adm/customer/account', data).then(
      (res: any) => {
        return res;
      },
      (err: any) => {
        throw new Error(err.error.err);
      }
    );
  }


  storeToken(token: string) {
    localStorage.setItem('LoggedInUser', token);
  }

  deleteToken() {
    localStorage.removeItem('LoggedInUser');
  }

  retrieveToken() {
    return localStorage.getItem('LoggedInUser');
  }

  isLoggednIn() {
    return (this.retrieveToken() !== null && this.retrieveLocalUser() !== null);
  }

  getName() {
    this.user = this.retrieveLocalUser();
    if (this.user) {
      return this.user.name;
    }
    return false;
  }

  getFirstName() {
    this.user = this.retrieveLocalUser();
    if (this.user) {
      return (this.user.name).split(' ')[0];
    }
    return false;
  }

  // return master, teacher, simple, partner or customer
  getAccountType() {
    this.user = this.retrieveLocalUser();
    if (this.user) {
      if (this.user.account_type) {
        return this.user.account_type;
      }
      return 'customer';
    }
    return false;
  }

  getAvatar() {
    this.user = this.retrieveLocalUser();
    if (this.user.account_type === 'master' || this.user.account_type === 'admin') {
      return 'assets/imgs/avatarsupertreinos.jpg';
    }
    if (this.user.account_type === 'teacher' || this.user.account_type === 'nutri') {
      return this.user.avatar;
    }
    if (this.user.details) {
      return this.user.details.avatar;
    }
    return false;
  }
  getLogo() {
    this.user = this.retrieveLocalUser();
    if (this.user) {
      if (this.user.subs) {
        if (this.user.subs.purchase.supertreinos_plan_id && this.user.subs.purchase.supertreinos_plan_id === '3' ) {
          (document.getElementById('super-coach-logo') as HTMLFormElement).src = this.user.logo;
          return this.user.logo;
        }
      }
    }
    // (document.getElementById('super-treinos-logo') as HTMLFormElement).src = 'assets/imgs/logo4x.png';
    return false;
  }

  hasAdminHash() {
    const admin = this.retrieveAdminHash();
    // console.log(admin);
    if (admin) {
      return admin.adminHash ? true : false;
    }
    return false;
  }
  retrieveAdminHash() {
    return JSON.parse(localStorage.getItem('canAdmin'));
  }
  storeAdminHash() {
    const date = new Date();
    const admin = { adminHash: date };
    window.localStorage.setItem('canAdmin', JSON.stringify(admin));
  }

  isTeacher() {
    this.user = this.retrieveLocalUser();
    if (this.user.account_type && (this.user.account_type === 'teacher')) {
      return true;
    }
    return false;
  }

  isNutri() {
    this.user = this.retrieveLocalUser();
    if (this.user.account_type && (this.user.account_type === 'nutri')) {
      return true;
    }
    return false;
  }

  isMaster() {
    this.user = this.retrieveLocalUser();
    if (this.user.account_type && (this.user.account_type === 'master')) {
      return true;
    }
    return false;
  }

  isPartner() {
    this.user = this.retrieveLocalUser();
    if (this.user.account_type && (this.user.account_type === 'partner')) {
      return true;
    }
    return false;
  }

  isAdmin() {
    this.user = this.retrieveLocalUser();
    if (this.user.account_type && (this.user.account_type === 'admin')) {
      return true;
    }
    return false;
  }

  isSimple() {
    this.user = this.retrieveLocalUser();
    if (this.user.account_type && (this.user.account_type === 'simple')) {
      return true;
    }
    return false;
  }

  storeLocallyUser(user: any) {
    window.localStorage.setItem('user', JSON.stringify(user));
    window.localStorage.setItem('loggedInHash', btoa(JSON.stringify(user)));
    return user;
  }

  retrieveLocalUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  checkUserHash() {
    const user = (localStorage.getItem('user'));
    const hash = (atob(localStorage.getItem('loggedInHash')));
    if (user === hash) {
      return true;
    }
    // TODO: mandar mensagem ao VINICIUS que este professor tentou burlar o ADMIN
    // if (user) this.logout();
    this.logout();
    return false;
  }

  logout() {
    // this.myRoute.navigate(['login'], { skipLocationChange: true });
    const user = (localStorage.getItem('user'));
    if (user) {
      console.log('logout');
      localStorage.removeItem('user');
      localStorage.removeItem('loggedInHash');
      localStorage.removeItem('LoggedInUser');
      localStorage.removeItem('canAdmin');
      localStorage.removeItem('teacherAccount');
      localStorage.removeItem('students');
      localStorage.clear();
      this.helpers.clearDB();
      window.location.reload();
      // if (('/precadastro/')) {
      //   return true;
      // }
    }
  }

  async requestValidEmail() {
    let message: any;
    return this.api.post('master/v2/user/validar-email').then(
      (res: any) => {
        // console.log(res);
        return res;
      }
    ).catch(
      (err: any) => {
        console.log(err);
        if (err.status === 422 || err.status === 404) {
          message = err.error.err;
        } else {
          message = 'Algo não funcionou bem. Tente novamente.';
        }
        throw new Error(message);
      }
    );
  }

}
