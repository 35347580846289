import { Component, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../../ui/dialog/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { HelpersProvider } from '../../providers/helpers/helpers';
import { ValuationProvider } from '../../providers/valuation/valuation';
import { I18nProvider } from '../../providers/i18n/i18n';

import { environment } from '../../environments/environment';

@Component({
  selector: 'dialog-valuation',
  templateUrl: 'dialog-valuation.html',
})

export class DialogValuationComponent {
  constructor(
    public toastr: ToastrService,
    private router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogValuationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = {user: null, valuation: null},
    public helpers: HelpersProvider,
    public valuationProv: ValuationProvider,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('DialogValuationComponent');
    this.dataSource = new MatTableDataSource();

    this.protocolos = [
      {name: this.PAGE.pollock_homem, id: 1},
      {name: this.PAGE.pollock_mulher, id: 2},
      {name: this.PAGE.bioimpedancia, id: 3},
    ]

    this.label_chart = this.STRINGS.peso;
    this.label = this.STRINGS.carregando3pt;
    
  }

  PAGE: any;
  STRINGS: any;

  protected API = (environment.API_ENV_URLS).replace('/api', '/');

  dataSource: MatTableDataSource<any>;
  displayedColumns = ['data', 'peso', 'pct_gordura', 'peso_gordo', 'peso_magro', 'imc'];
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  showForm: boolean = false;
  loading: boolean = false;

  banners: any = [];
  banners_std: any = [];
  banner_selected = '';
  user: any = { gender: 'masc' };
  customer: any;
  avaliacaoData: any = { created_at: '2025-05-11 18:19:33'}
  protocolos: any;
  imc_result: string = '';

  valuations: any = [];
  valuation: any = null;
  valuationForm = new FormGroup({
    // peso: new FormControl('', [Validators.required]),
    id: new FormControl(''),
    customer_id: new FormControl(''),
    user_id: new FormControl(''),
    peso: new FormControl(''),
    altura: new FormControl(''),
    idade: new FormControl(''),
    meta_gordura: new FormControl(''),
    subescapular: new FormControl(''),
    tricipital: new FormControl(''),
    peitoral: new FormControl(''),
    axilar_media: new FormControl(''),
    supra_iliaca: new FormControl(''),
    abdominal: new FormControl(''),
    coxa: new FormControl(''),
    gordura_ideal: new FormControl(''),
    gordura_atual: new FormControl(''),
    densidade_corporal: new FormControl(''),
    peso_magro: new FormControl(''),
    peso_gordo: new FormControl(''),
    peso_ideal: new FormControl(''),
    imc: new FormControl(''),
    protocolo_id: new FormControl(''),
    dados_balanca: new FormGroup({
      db_gordura: new FormControl(''),
      db_musculo_esqueletico: new FormControl(''),
      db_idade_corporal: new FormControl(''),
      db_metabolismo_basal: new FormControl(''),
      db_gordura_visceral: new FormControl(''),
      db_agua_corporal: new FormControl(''),
      db_massa_muscular: new FormControl(''),
      db_massa_gorda: new FormControl(''),
      db_massa_ossea: new FormControl(''),
    }),
    perimetros_centrais: new FormGroup({
      pc_ombros: new FormControl(''),
      pc_torax: new FormControl(''),
      pc_cintura: new FormControl(''),
      pc_abdominal: new FormControl(''),
      pc_quadril: new FormControl(''),
    }),
    perimetros_perifericos: new FormGroup({
      pp_antebraco_d: new FormControl(''),
      pp_braco_relaxado_d: new FormControl(''),
      pp_braco_contraido_d: new FormControl(''),
      pp_coxa_proximal_d: new FormControl(''),
      pp_coxa_media_d: new FormControl(''),
      pp_panturrilha_d: new FormControl(''),
      pp_antebraco_e: new FormControl(''),
      pp_braco_relaxado_e: new FormControl(''),
      pp_braco_contraido_e: new FormControl(''),
      pp_coxa_proximal_e: new FormControl(''),
      pp_coxa_media_e: new FormControl(''),
      pp_panturrilha_e: new FormControl(''),
    }),
  });

  // options
  data_chart: any[];
  data_chart_pie: any[];
  label_chart: string = 'peso';
  label: string = 'carregando...';
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  view: any[] = [500, 280];
  view_pie: any[] = [240, 250];

  imgrnd: number = 0;

  colorScheme = {
    domain: [ '#5151f1', '#47dcdc', '#f4dd43', '#cd3036', '#009966']
  };
  colorSchemePie = {
    domain: [ '#47dcdc', '#f4dd43']
  };
  cardColor: string = '#232837';  

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.customer = this.data.customer;
    this.imgrnd = this.helpers.random(1000001,9999999);
    this.getValuations();
  }

  getValuations() {
    this.loading = true;
    this.valuationProv.index(this.customer.id).then(
      (res: any) => {
        this.loading = false;
        this.valuations = res.valuations.reverse();
        this.processValuations();

        this.dataSource = new MatTableDataSource(this.valuations);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;        
      },
      (err: any) => {
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
      }
    );
  }

  processValuations() {
    let val = this.valuations;
    for (let i=0; i<val.length; i++) {
      // data 
      const dt = val[i].created_at.split(' ');
      const date = new Date(dt[0]);
      val[i].data = date.getTime();

      // pct_gordura
      let pct_gordura = val[i].gordura_atual;
      if (pct_gordura === '' || pct_gordura === null || pct_gordura === 0 || pct_gordura === '0') {
        val[i].pct_gordura = 0;
        val[i].peso_gordo = 0;
        val[i].peso_magro = 0;
      } else {
        pct_gordura = parseFloat(pct_gordura.replace(',','.'));
        val[i].pct_gordura = pct_gordura;
        val[i].peso_gordo = this.getPesoGordo(val[i]);
        val[i].peso_magro = this.getPesoMagro(val[i]);  
      }

      val[i].imc = this.getIMC(val[i]);

      // this.valuations[i] = val[i];
    }
  }

  select(item: any) {
    this.valuation = Object.assign({}, item);
    this.showForm = true;
    this.formatMedidas();
    this.fillForm();
  }

  new() {
    let peso: number = parseFloat(this.customer.details.weight);
    let altura: number = parseFloat(this.customer.details.height);
    let idade: number = (this.customer.details.birthdate !== null && this.customer.details.birthdate !== '') ? this.helpers.getAge(this.customer.details.birthdate) : 0;
    let sexo: string = this.customer.details.gender;
    let protocolo_id: number = ((sexo === 'm') ? 1 : 2);
  
    let yourDate = new Date()
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - (offset*60*1000));
    let hoje = yourDate.toISOString().split('T')[0];
    this.valuation = {
      created_at: hoje
    };
    this.showForm = true;
    this.valuationForm.reset();
    this.valuationForm.patchValue({ protocolo_id: protocolo_id });
    this.valuationForm.patchValue({
      id: 0,
      customer_id: this.customer.id,
      user_id: this.customer.user_id,
      peso: parseFloat(peso.toFixed(3)),
      altura: parseFloat(altura.toFixed(2)),
      idade: idade,
    });
    if (protocolo_id !== 3) {
      this.valuationForm.patchValue({
        meta_gordura: this.getMetaGordura(idade, this.customer.details.gender),
      });  
    }

    let imc = (this.getIMC(this.valuationForm.value));
    this.valuationForm.patchValue({ imc: imc });
       
    this.formatMedidas();

    setTimeout(() => {
      this.processData(this.valuationForm.value);
    }, 300);
  }

  referencias() {
    if (this.valuationForm.value.protocolo_id === 3) return false;
    const msg = '' +
    '<div class="referencias">' +
      '<h3>'+this.PAGE.referencias+'</h3>' +
      '<div class="acms">' +
        '<h5>'+this.PAGE.tabela_acms+'</h5>' +
        '<div class="table t-acms">' +
          '<div class="row">' +
            '<div class="tag">'+this.PAGE.tabela_acms_idades+'</div>' +
          '</div>' +
          '<div class="row">' +
            '<div class="tag">'+this.PAGE.tabela_acms_homens+'</div>' +
          '</div>' +
          '<div class="row">' +
            '<div class="tag">'+this.PAGE.tabela_acms_mulheres+'</div>' +
          '</div>' +
        '</div>' +
      '</div>' +
      '<div class="imc">' +
        '<h5>'+this.PAGE.tabela_imc+'</h5>' +
        '<div class="table t-imc">' +
          '<div class="row">' +
            '<div class="tag">'+this.PAGE.tabela_imc_baixo+'</div>' +
            '<div class="tag double">'+this.PAGE.tabela_imc_baixo_tag+'</div>' +
          '</div>' +
          '<div class="row">' +
            '<div class="tag">'+this.PAGE.tabela_imc_ideal+'</div>' +
            '<div class="tag double">'+this.PAGE.tabela_imc_ideal_tag+'</div>' +
          '</div>' +
          '<div class="row">' +
            '<div class="tag">'+this.PAGE.tabela_imc_sobre+'</div>' +
            '<div class="tag double">'+this.PAGE.tabela_imc_sobre_tag+'</div>' +
          '</div>' +
        '</div>' +
      '</div>' +
    '</div>';
  
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      panelClass: 'ref-modal',
      data: {title: this.PAGE.referencias, inner_msg: msg, confirmation: 'deletar', bt_cancel_hide: true}
    });
  }

  delete(valuation: any) {
    const msg = this.PAGE.deletar_avaliacao;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.deletar_avaliacao_title, message: msg, confirmation: 'deletar'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'deletar') {
        console.log(valuation);
        this.loading = true;
        this.valuationProv.delete(valuation.id).then(
          (res: any) => {
            this.loading = false;
            this.valuations = res.valuations.reverse();

            this.toastr.success(this.customer.name+this.PAGE.deletar_avaliacao_sucesso, '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            this.loading = false;
            console.log(err);
            this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
          }
        );    
      } 
    });

  }

  back() {
    this.valuation = null;
    this.showForm = false;

    this.processValuations();
    this.dataSource = new MatTableDataSource(this.valuations);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  save() {
    this.loading = true;

    let valuation = Object.assign({}, this.valuationForm.value);
    valuation.perimetros_centrais = JSON.stringify(valuation.perimetros_centrais);
    valuation.perimetros_perifericos = JSON.stringify(valuation.perimetros_perifericos);
    valuation.dados_balanca = JSON.stringify(valuation.dados_balanca);
    valuation.gordura_atual = (valuation.gordura_atual === '' || valuation.gordura_atual === null) ? 0 : valuation.gordura_atual;

    if (valuation.id > 0) {
      this.valuationProv.put(valuation).then(
        (res: any) => {
          this.loading = false;
          this.valuations = res.valuations.reverse();
  
          this.toastr.success(this.customer.name+this.PAGE.salvar_avaliacao_sucesso, '', this.helpers.toastrCfgs);
        },
        (err: any) => {
          this.loading = false;
          console.log(err);
          this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        }
      );
    } else {

      this.valuationProv.post(valuation).then(
        (res: any) => {
          this.loading = false;
          this.valuations = res.valuations;
          this.dataSource = new MatTableDataSource(this.valuations);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;  
          this.toastr.success('Os dados do '+this.customer.name+' foram atualizados com sucesso!', '', this.helpers.toastrCfgs);
        },
        (err: any) => {
          this.loading = false;
          this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
        }
      );
    }
  }




  callToSubs(banner: any) {
    const msg = this.STRINGS.recurso_exclusivo;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.STRINGS.recurso_exclusivo_title, message: msg, confirmation: 'Sim'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim') {
        this.dialogRef.close(banner);
        this.router.navigate(['professor/perfil'], { skipLocationChange: true });
      }
    });
  }

  changeField() {
    // imc
    let imc: string = '';
    if (this.valuationForm.value.peso !== '' && this.valuationForm.value.altura !== '') {
      imc = this.getIMC(this.valuationForm.value);
      this.valuationForm.patchValue({ imc: imc });
    }

    // pollock
    let idade: number = this.valuationForm.value.idade;
    let const1: number = (this.valuationForm.value.protocolo_id === 1) ? 1.112 : 1.097;
    let const2: number = (this.valuationForm.value.protocolo_id === 1) ? 0.00043499 : 0.00046971;
    let const3: number = (this.valuationForm.value.protocolo_id === 1) ? 0.00000055 : 0.00000056;
    let const4: number = (this.valuationForm.value.protocolo_id === 1) ? 0.00028826 : 0.00012828;

    let soma: number =  parseFloat((this.valuationForm.value.subescapular)) + 
                        parseFloat((this.valuationForm.value.tricipital)) + 
                        parseFloat((this.valuationForm.value.peitoral)) +
                        parseFloat((this.valuationForm.value.axilar_media)) +
                        parseFloat((this.valuationForm.value.supra_iliaca)) +
                        parseFloat((this.valuationForm.value.abdominal)) +
                        parseFloat((this.valuationForm.value.coxa));

    if (this.valuationForm.value.protocolo_id !== '' && this.valuationForm.value.protocolo_id !== null) {
      // pollock
      if (this.valuationForm.value.protocolo_id === 1 || this.valuationForm.value.protocolo_id === 2) {
        if (!isNaN(soma)) {
          let densidade_corporal = const1 - (const2 * soma) + (const3 * (soma * soma)) - (const4 * idade);
          this.valuationForm.patchValue({ densidade_corporal: densidade_corporal });
    
          let gordura_atual: number = (((4.95 / densidade_corporal) - 4.50) * 100); // Siri, 1961
          // this.valuationForm.patchValue({ gordura_atual: gordura_atual.toFixed(1).replace('.',',') });
          this.valuationForm.patchValue({ gordura_atual: this.formatDecimal(gordura_atual, 1) });
    
          // meta gordura
          this.formatMedidas();
    
          setTimeout(() => {
            this.processData(this.valuationForm.value);
          }, 300);          
        }
      }

      // bioimpedancia
      if (this.valuationForm.value.protocolo_id === 3) {
        let valuation = Object.assign({}, this.valuationForm.value);

        this.valuationForm.patchValue({ densidade_corporal: 0 });  
        this.valuationForm.patchValue({ gordura_atual: (valuation.dados_balanca.db_gordura || 0) });

        setTimeout(() => {

          this.data_chart_pie = [
            { "name":  this.PAGE.pesomagro,
              "value": (valuation.dados_balanca.db_massa_muscular || 0),
            },
            { "name": this.PAGE.pesogordo,
              "value": (valuation.dados_balanca.db_massa_gorda || 0),
            },
          ];
        }, 300);          
      }
    }

  }

  getMetaGordura(idade: number, sexo) {
    if (idade >= 18 && idade <= 29) {
      if (sexo === "m") {
        return 14;
      } else {
        return 19;
      }
    }
    if (idade >= 30 && idade <= 39) {
      if (sexo === "m") {
        return 16;
      } else {
        return 21;
      }
    }
    if (idade >= 40 && idade <= 49) {
      if (sexo === "m") {
        return 17;
      } else {
        return 22;
      }
    }
    if (idade >= 50 && idade <= 59) {
      if (sexo === "m") {
        return 18;
      } else {
        return 23;
      }
    }
    if (idade <= 60) {
      if (sexo === "m") {
        return 21;
      } else {
        return 26;
      }
    }
    return 1;

  }

  formatMedidas() {
    let valuation = this.valuationForm.value;
    if (this.customer.details.weight === '' || this.customer.details.weight === null) return false;
    if (this.customer.details.peso === '' || this.customer.details.peso === null) return false;

    let idade: number = this.valuationForm.value.idade;
    // (valuation.peso) = (valuation.peso).replace(',','.');
    if (valuation.protocolo_id !== 3) {
      this.valuationForm.patchValue({
        meta_gordura: this.getMetaGordura(idade, this.customer.details.gender),
      });  
    }
    this.valuationForm.patchValue({
      gordura_ideal: this.getMetaGordura(idade, this.customer.details.gender),
      peso_magro: this.getPesoMagro(valuation),
      peso_gordo: this.getPesoGordo(valuation),
      peso_ideal: this.getPesoIdeal(valuation),
    });
  }

  formatDecimal(value: any, tofix: number = 1) {
    if (value === undefined || value === null || value === '') return '0';

    let new_value = value;

    if (tofix > 0) {
      new_value = new_value.toFixed(tofix);
    }

    if (this.STRINGS.lang === 'br') {
      new_value = new_value.replace('.',',')
    }
    if (this.STRINGS.lang === 'en') {
      new_value = new_value.replace(',','.')
    }

    return new_value;
  }

  fillForm() {
    if (this.valuation !== null && this.valuation !== '') {
      let valuation = Object.assign({}, this.valuation);
      // (valuation.peso) = (valuation.peso).replace('.',',');
      (valuation.peso) = this.formatDecimal(valuation.peso, 0);

      if (valuation.perimetros_centrais === null) {
        valuation.perimetros_centrais = {
          pc_ombros: '',
          pc_torax: '',
          pc_cintura: '',
          pc_abdominal: '',
          pc_quadril: '',  
        }
      } else {
        valuation.perimetros_centrais = JSON.parse(valuation.perimetros_centrais);
      }
      if (valuation.perimetros_perifericos === null) {
        valuation.perimetros_perifericos = {
          pp_antebraco_d: '',
          pp_braco_relaxado_d: '',
          pp_braco_contraido_d: '',
          pp_coxa_proximal_d: '',
          pp_coxa_media_d: '',
          pp_panturrilha_d: '',
          pp_antebraco_e: '',
          pp_braco_relaxado_e: '',
          pp_braco_contraido_e: '',
          pp_coxa_proximal_e: '',
          pp_coxa_media_e: '',
          pp_panturrilha_e: '',
        }
      } else {
        valuation.perimetros_perifericos = JSON.parse(valuation.perimetros_perifericos);
      }

      if (valuation.dados_balanca === null) {
        valuation.dados_balanca = {
          db_gordura: '',
          db_musculo_esqueletico: '',
          db_idade_corporal: '',
          db_metabolismo_basal: '',
          db_gordura_visceral: '',
          db_agua_corporal: '',
          db_massa_muscular: '',
          db_massa_gorda: '',
          db_massa_ossea: '',
        }
      } else {
        valuation.dados_balanca = JSON.parse(valuation.dados_balanca);
      }

      this.valuationForm.reset();
      this.valuationForm.patchValue(valuation);
      
      this.changeField();

      setTimeout(() => {
        this.processData(this.valuationForm.value);
      }, 300);

    }
  }

  getPesoGordo(valuation: any) {
    let val = Object.assign({}, valuation);
    if (val.gordura_atual === '' || val.gordura_atual === null) return '0';

    let peso_magro;
    // Gordura Absoluta (kg): = (peso corporal x %G) / 100
    let gordura_absoluta = (parseFloat(val.peso) * parseFloat(val.gordura_atual)) / 100;
    // Massa Magra (kg): = (peso corporal – Gordura Absoluta)
    peso_magro = parseFloat(val.peso) - gordura_absoluta;

    let peso_gordo;
    peso_gordo = parseFloat(val.peso) - parseFloat(peso_magro);
    // return (peso_gordo).toFixed(1).replace('.',',');
    return this.formatDecimal(peso_gordo, 1);
  }

  getPesoMagro(valuation: any) {
    let val = Object.assign({}, valuation);
    if (val.gordura_atual === '' || val.gordura_atual === null) return '0';

    let peso_magro;
    // Gordura Absoluta (kg): = (peso corporal x %G) / 100
    let gordura_absoluta = (parseFloat(val.peso) * parseFloat(val.gordura_atual)) / 100;
    // Massa Magra (kg): = (peso corporal – Gordura Absoluta)
    peso_magro = parseFloat(val.peso) - gordura_absoluta;

    // return (peso_magro).toFixed(1).replace('.',',');
    return this.formatDecimal(peso_magro, 1);
  }

  getPesoIdeal(valuation: any) {
    let val = Object.assign({}, valuation);

    let altura2 = ((parseFloat(val.altura)/100) * (parseFloat(val.altura)/100));
    let imc: number = 21.7; // media ideal do IMC
    let pesoIdeal = imc * altura2;

    // return (pesoIdeal).toFixed(1).replace('.',',');
    return this.formatDecimal(pesoIdeal, 1);
  }

  getIMC(valuation: any) {
    let val = Object.assign({}, valuation);
    if (typeof val.peso === 'string' || val.peso instanceof String) {
      (val.peso) = (val.peso).replace(',','.');
    }
    let imc = parseFloat(String(val.peso)) / (parseFloat(String(val.altura))/100 * parseFloat(String(val.altura))/100);
    
    if (this.showForm) {
      if (imc > 40) this.imc_result = this.PAGE.obesidade_morbida;
      if (imc <= 39.9) this.imc_result = this.PAGE.obesidade_mnoderada;
      if (imc <= 34.9) this.imc_result = this.PAGE.obesidade_leve;
      if (imc <= 29.9) this.imc_result = this.PAGE.sobrepeso;
      if (imc <= 24.9) this.imc_result = this.PAGE.idealpeso;
      if (imc < 18.5) this.imc_result = this.PAGE.pesobaixo;  
    }
    // imc = Math.round(imc * 1e2) / 1e2;
    // return (imc.toFixed(2)).replace('.',',');
    return this.formatDecimal(imc, 2);
  }

  processData(valuation: any) {
    //(valuation.peso) = (valuation.peso).replace(',','.');
    let altura3 = (parseFloat(valuation.altura) * parseFloat(valuation.altura));
    let ossea = 0.075 * altura3 * parseFloat(valuation.peso) / 10000;
    let muscular = (0.46 * parseFloat(valuation.peso)) - 10.6;
    let orgaos = 0.11 * parseFloat(valuation.peso);
    let residuos = parseFloat(valuation.peso) - (ossea + muscular + orgaos);

    // 1e1 = 1 digit // 1e2 = 2 digit // 1e3 = 3 digit
    muscular = Math.round(muscular * 1e2) / 1e2;
    ossea = Math.round(ossea * 1e2) / 1e2;
    orgaos = Math.round(orgaos * 1e2) / 1e2;
    residuos = Math.round(residuos * 1e2) / 1e2;

    let excesso = parseFloat(valuation.peso) - parseFloat(valuation.peso_ideal);
    excesso = Math.round(excesso * 1e2) / 1e2;

    let gordura_atual = parseFloat(valuation.gordura_atual);

    let peso_magro;
    let gordura_absoluta = (parseFloat(valuation.peso) * parseFloat(valuation.gordura_atual)) / 100;
    peso_magro = parseFloat(valuation.peso) - gordura_absoluta;
    peso_magro = Math.round(peso_magro * 1e2) / 1e2;

    let peso_gordo;
    peso_gordo = parseFloat(valuation.peso) - parseFloat(peso_magro);
    peso_gordo = Math.round(peso_gordo * 1e2) / 1e2;

    this.data_chart = [
      { "name": this.PAGE.gorduraatual,
        "value": gordura_atual,
      },
      { "name": this.PAGE.pesomagro,
        "value": peso_magro,
      },
      { "name": this.PAGE.pesogordo,
        "value": peso_gordo,
      },
      { "name": this.PAGE.pesoideal,
        "value": peso_magro + peso_gordo - excesso,
      },
      { "name": this.PAGE.excesso,
        "value": excesso,
      },
    ];
    this.data_chart_pie = [
      { "name":  this.PAGE.pesomagro,
        "value": peso_magro,
      },
      { "name": this.PAGE.pesogordo,
        "value": peso_gordo,
      },
    ];
    this.label = 'teste aaaa';

    if (this.valuationForm.value.protocolo_id === 3) {
      let valuation = Object.assign({}, this.valuationForm.value);

      this.valuationForm.patchValue({ densidade_corporal: 0 });  
      this.valuationForm.patchValue({ gordura_atual: (valuation.dados_balanca.db_gordura || 0) });

      setTimeout(() => {

        this.data_chart_pie = [
          { "name":  this.PAGE.pesomagro,
            "value": (valuation.dados_balanca.db_massa_muscular || 0),
          },
          { "name": this.PAGE.pesogordo,
            "value": (valuation.dados_balanca.db_massa_gorda || 0),
          },
        ];
      }, 300);          
    }
  }



  onSelect(_data: any): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(_data)));
  }

  onActivate(_data: any): void {
    // console.log('Activate', JSON.parse(JSON.stringify(_data)));
  }

  onDeactivate(_data: any): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(_data)));
  }  

  handlePageEvent(evt: any = null) {
    this.helpers.storeLocally('lastPageSizeValuation', evt.pageSize);
  }

  percentageFormatting(c) {
    return Math.round(c);
  }

}

// export interface DialogData {
//   title: string;
//   message: string;
//   confirmation: string;
//   bt_ok?: string;
// }

